<script>
  import Button from './controls/button.svelte'
  import { tabs } from '../modules/store.module'
 
  $: activeTabIndex = $tabs.items.findIndex(isActiveTab)
  const isActiveTab = ({ active }) => active
  const activateTab = (direction) => {
    if (direction === 'previous') $tabs.previous()
    if (direction === 'next') $tabs.next()
    const top = document.querySelector('.quarters').getBoundingClientRect().top + window.scrollY
    window.scrollTo({ top, behavior: 'instant' })
  }
</script>

<div class="navigation">
  <slot></slot>
  <div class="navigation__buttons">
    <Button disabled={activeTabIndex === 0} on:click={() => activateTab('previous')} type={'secondary'}>Назад</Button>
    <Button disabled={activeTabIndex === $tabs.items.length - 1} on:click={() => activateTab('next')} type={'primary'}>Далее</Button>
  </div>
</div>

<style lang="scss">
  .navigation {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-areas: 
            "a b c d e";
    column-gap: 16px;
    align-items: center;
    padding: 16px 0;
    border-top: 1px solid #E7E5E4;
    background-color: white;
    position: sticky;
    bottom: 0;
    @media (max-width: 768px) {
      position: initial;
      border-top: none;
      padding: 0;
    }

    & > :global(*) {
      &:nth-child(1) {
        grid-area: a;
      }
      &:nth-child(2) {
        grid-area: b;
      }
      &:nth-child(3) {
        grid-area: c;
      }
      &:nth-child(4) {
        grid-area: d;
      }
    }

    @media (max-width: 1279px) {
      grid-template-columns: repeat(6, 1fr);
      grid-template-areas: 
            "a a a b b b"
            "c c c d d d"
            "x x x x e e";
    }
    @media (max-width: 639px) {
      grid-template-columns: 1fr;
      grid-template-areas: 
            "a"
            "b"
            "c"
            "d"
            "e";
    }
    &__buttons {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
      justify-content: flex-end;
      grid-area: e!important;
      padding: 20px 0;
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
</style>