export const get = (requestURL, requestHeaders, contentType) => sendRequest(requestURL, 'GET', null, requestHeaders, contentType)
export const del = (requestURL, requestHeaders, contentType) => sendRequest(requestURL, 'DELETE', null, requestHeaders, contentType)
export const post = (requestURL, requestBody, requestHeaders, contentType) => sendRequest(requestURL, 'POST', requestBody, requestHeaders, contentType)
export const put = (requestURL, requestBody, requestHeaders, contentType) => sendRequest(requestURL, 'PUT', requestBody, requestHeaders, contentType)
export const patch = (requestURL, requestBody, requestHeaders, contentType) => sendRequest(requestURL, 'PATCH', requestBody, requestHeaders, contentType)

const sendRequest = async (requestURL, requestMethod, requestBody, requestHeaders, contentType) => {
  try {
    const request = await fetch(requestURL, {
      method: requestMethod,
      headers: {
        ...requestHeaders,
        'Content-Type': contentType || 'application/json'
      },
      ...(requestBody) && { body: JSON.stringify(requestBody) }
    })
    if (!request.ok) {
      const responseBody = await request.text()
      const { status: responseStatus, statusText: responseStatusText } = request
    }
    if (request.status === 204) return
    const text = await request.text()
    const responseContentType = request.headers.get('content-type')
    return responseContentType.includes('application/json') ? JSON.parse(text) : text
  }
  catch (e) {
    //console.log(e)
  }
}