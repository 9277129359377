<script>
  import { activeOnboardingStep } from '../modules/store.module'
  import BigNumber from '../modules/bignumber.module'
  import { monthMap } from '../modules/time.module'
  import Input from './controls/input.svelte'
  import Toggle from './controls/toggle.svelte'
  import Navigation from './navigation.svelte'
  import Hint from './controls/hint.svelte'

  export let incomeDeduction
  export let inferiorDeduction
  export let specialStatusDeduction
  export let socialDeduction
  export let propertyDeduction
  export let standardDeduction
  export let childrenDeduction
  export let dependentsDeduction
  export let totalDeduction
  export let isEmployed
  export let doesExist
  export let isSpecialParent
  export let isSpecialStatus
  export let children
  export let dependents
  export let inferiors
  export let profit
  export let customProperties
  export let number
  export let year

  const REGULAR_DEDUCTION = BigNumber(56)
  const ADVANCED_DEDUCTION = BigNumber(107)
  const INCOME_DEDUCTION_LIMIT = BigNumber(3496)
  const MONTHLY_INCOME_DEDUCTION = BigNumber('192,00')
  const MONTHLY_STATUS_DEDUCTION = BigNumber('272,00')

  const isUnderIncomeLimit = () => BigNumber(profit).isLessThanOrEqualTo(INCOME_DEDUCTION_LIMIT)

  const getMonthsWhenUnemployedAndExists = () => 3 - isEmployed.map((isEmployedInMonth, monthIndex) => isEmployedInMonth || !doesExist[monthIndex]).filter(Boolean).length
  const sumArray = (array) => array.reduce((sum, item) => sum.plus(BigNumber(item || 0)), BigNumber(0)).toFormat(2)

  const toggleProperty = (propertyName) => {
    if (!customProperties) customProperties = []
    if (!customProperties.includes(propertyName)) return customProperties = [...customProperties, propertyName]
    customProperties = customProperties.filter(customProperty => customProperty !== propertyName)
  }
  const calculateIncomeDeduction = () => isUnderIncomeLimit() ? MONTHLY_INCOME_DEDUCTION.times(getMonthsWhenUnemployedAndExists()).toFormat(2) : '0,00'
  const calculateChildrenDeduction = () => children.reduce((deduction, childrenInMonth, monthIndex) => {
    if (isEmployed[monthIndex]) return deduction
    const deductionPerChild = isSpecialParent[monthIndex] || childrenInMonth >= 2 ? ADVANCED_DEDUCTION : REGULAR_DEDUCTION
    return deductionPerChild.times(childrenInMonth || 0).plus(deduction)
  }, BigNumber(0)).toFormat(2)
  const calculateDependentsDeduction = () => dependents.reduce((deduction, dependentsInMonth, monthIndex) => {
    if (isEmployed[monthIndex]) return deduction
    const deductionPerDependent = isSpecialParent[monthIndex] ? ADVANCED_DEDUCTION : REGULAR_DEDUCTION
    return deductionPerDependent.times(dependentsInMonth || 0).plus(deduction)
  }, BigNumber(0)).toFormat(2)
  const calculateInferiorDeduction = () => BigNumber(childrenDeduction || 0).plus(BigNumber(dependentsDeduction || 0)).toFormat(2)
  const calculateSpecialStatusDeduction = () => isSpecialStatus?.reduce((deduction, isSpecialStatusInMonth, monthIndex) => {
    if (isEmployed[monthIndex]) return deduction
    return (isSpecialStatusInMonth ? MONTHLY_STATUS_DEDUCTION : BigNumber('0,00')).plus(deduction) 
  }, BigNumber(0)).toFormat(2)
  
  $: if (isEmployed && profit && !customProperties?.includes('incomeDeduction')) {
    incomeDeduction = calculateIncomeDeduction()
  }
  $: if (isEmployed && isSpecialParent && children) { 
    childrenDeduction = calculateChildrenDeduction()
  }
  $: if (isEmployed && isSpecialParent && dependents) { 
    dependentsDeduction = calculateDependentsDeduction()
  }
  $: if (childrenDeduction && dependentsDeduction && !customProperties?.includes('inferiorDeduction')) {
    inferiorDeduction = calculateInferiorDeduction()
  }
  $: if (isSpecialStatus && !customProperties?.includes('specialStatusDeduction')) {
    specialStatusDeduction = calculateSpecialStatusDeduction()
  }
  $: inferiors = children.map((childrenInMonth, monthIndex) => BigNumber(childrenInMonth).plus(BigNumber(dependents[monthIndex])).toNumber())
  $: standardDeduction = sumArray([incomeDeduction, inferiorDeduction, specialStatusDeduction])
  $: totalDeduction = sumArray([incomeDeduction, inferiorDeduction, specialStatusDeduction, socialDeduction, propertyDeduction])

</script>

<section class="deductions">
  {#each [0,1,2] as monthIndex}
    {@const numberOfFirstMonthInQuarter = ((number - 1) * 3) + 1}
    {@const monthNumber = numberOfFirstMonthInQuarter + monthIndex} 
    {@const monthName = monthMap.get(monthNumber)} 
    <div class="deductions__item">
      <div class="deductions__heading">За {monthName}:</div>
      <div class="deductions__inputs">
        <Input bind:value={children[monthIndex]} type={'integer'} tooltipVisible={monthIndex === 0}>
          <span slot="label">Кол. детей</span>
          <svelte:fragment slot='tooltip'>
            <p>Указывается количество детей до 18 лет в каждом месяце квартала.</p>
            <p>Факт наличия у вас права на данный вычет подтверждается в ИМНС документально. Подробнее: п. 3 ст. 209 НК РБ.</p>
          </svelte:fragment>
          <svelte:fragment slot="hint">
            {#if $activeOnboardingStep?.name === 'Дети' && $activeOnboardingStep?.monthIndex === monthIndex && $activeOnboardingStep?.quarter.year === year && $activeOnboardingStep?.quarter.number === number}
              <Hint shifted={true}>Укажите количество детей за {monthName}.</Hint>
            {/if}
          </svelte:fragment>
        </Input>
        <Input bind:value={dependents[monthIndex]} type={'integer'} tooltipVisible={monthIndex === 0}>
          <span slot="label">Кол. иждивенцев</span>
          <svelte:fragment slot="tooltip">
            <p>Указывается количество иждивенцев в каждом месяце квартала. Иждивенцами признаются:</p>
            <p>– физические лица, находящиеся в отпуске по уходу за ребенком или осуществляющие уход за ребенком до достижения им возраста 3 лет – для супруга (супруги), а при его (ее) отсутствии – для физического лица признанного родителем ребенка;</p>
            <p>– обучающиеся старше 18 лет, получающие в дневной форме общее среднее, специальное, первое профессионально-техническое, первое среднее специальное, первое высшее образование, – для их родителей;</p>
            <p>– несовершеннолетние, над которыми установлены опека или попечительство, – для опекунов или попечителей этих несовершеннолетних;</p>
            <p>– инвалиды I и II группы старше 18 лет – для их родителей (усыновителей, удочерителей), супруга (супруги), опекуна или попечителя.</p>
            <p>Подробнее: п. 1.2 ст. 209 НК РБ.</p>
            <p>Факт наличия у вас права на данный вычет подтверждается в ИМНС документально. Подробнее: п. 3 ст. 209 НК РБ.</p>
          </svelte:fragment>
          <svelte:fragment slot="hint">
            {#if $activeOnboardingStep?.name === 'Иждивенцы' && $activeOnboardingStep?.monthIndex === monthIndex && $activeOnboardingStep?.quarter.year === year && $activeOnboardingStep?.quarter.number === number}
              <Hint shifted={true}>Укажите количество иждивенцев за {monthName}.</Hint>
            {/if}
          </svelte:fragment>
        </Input>
      </div>
      <div class="deductions__toggles">
        <div class="deductions__toggle">
          <Toggle bind:checked={isEmployed[monthIndex]} tooltipVisible={monthIndex === 0}>
            <svelte:fragment slot="label">Было основное место работы</svelte:fragment>
            <svelte:fragment slot="tooltip">
              <p>Включите этот флажок в тех месяцах квартала в которых у вас было основное место работы. При наличии основного места работы все положенные вам налоговые вычеты должен применять ваш работодатель.</p>
              <p>Подробнее: п. 2 ст. 209, п. 2 ст. 210, п. 1.1 ст. 211 НК РБ</p>
            </svelte:fragment>
            <svelte:fragment slot="hint">
              {#if $activeOnboardingStep?.name === 'Статусы' && $activeOnboardingStep?.monthIndex === monthIndex && $activeOnboardingStep?.quarter.year === year && $activeOnboardingStep?.quarter.number === number}
                <Hint>Если вы имели основное место работы или применяли вычеты за {monthName}, отметьте соответствующие флажки.</Hint>
              {/if}
            </svelte:fragment>
          </Toggle>
        </div>
        <div class="deductions__toggle">
          <Toggle bind:checked={isSpecialParent[monthIndex]} tooltipVisible={monthIndex === 0}>
            <svelte:fragment slot="label">Особый статус родителя</svelte:fragment>
            <svelte:fragment slot="tooltip">
              <p>Включите этот флажок в тех месяцах квартала, в которых у вас было основание на налоговые вычеты как у вдовы (вдовца), одинокого родителя, приемного родителя, опекуна или попечителя. Также этот флажок необходимо включить родителям, имеющим детей-инвалидов в возрасте до 18 лет в каждом месяце квартала. Подробнее: п. 1.2 ст. 209 НК РБ.</p>
              <p>Факт наличия у вас права на данный вычет подтверждается в ИМНС документально. Подробнее: п. 3 ст. 209 НК РБ.</p>
            </svelte:fragment>
          </Toggle>
        </div>
        <div class="deductions__toggle">
          <Toggle bind:checked={isSpecialStatus[monthIndex]} tooltipVisible={monthIndex === 0}> 
            <svelte:fragment slot="label">Особый социальный статус</svelte:fragment>
            <svelte:fragment slot="tooltip">
              <p>Включите этот флажок в тех месяцах квартала, в которых у вас было основание на налоговые вычеты как у:</p>
              <p>– инвалида I и II группы независимо от причин инвалидности;</p>
              <p>– ликвидатора или пострадавшего от аварии на ЧАЭС или других радиационных аварий;</p>
              <p>– участника ВОВ, героя труда, героя СССР, героя Беларуси и др.</p>
              <p>Подробнее: п. 1.3 ст. 209 НК РБ.</p>
              <p>Факт наличия у вас права на данный вычет подтверждается в ИМНС документально. Подробнее: п. 4 ст. 209 НК РБ.</p>
            </svelte:fragment>
          </Toggle>
        </div>
      </div>
    </div>
  {/each}
  <div class="deductions__item">
    <div class="deductions__heading">За год:</div>
    <Input bind:value={socialDeduction} enabled={number === 4} type={'float'} postfix={'BYN'}>
      <span slot="label">Социальный вычет</span>
      <svelte:fragment slot="tooltip">
        <p>Указывается годовая сумма расходов на:</p>
        <p>– ваше обучение, обучение детей или лиц, состоящих с вами в отношении близкого родства при получении первого образования в РБ;</p>
        <p>– добровольное страхование жизни и дополнительной пенсии сроком не менее 3 лет для вас или лиц, состоящих с вами в отношении близкого родства;</p>
        <p>– добровольное страхование медицинских расходов для вас или лиц, состоящих с вами в отношении близкого родства.</p>
        <p>Подробнее: п. 1.1 – 1.2 ст. 210 НК РБ.</p>
        <p>Факт наличия у вас права на данный вычет подтверждается в ИМНС документально. Подробнее: п. 3 – 6 ст. 210 НК РБ</p>
      </svelte:fragment>
    </Input>
    <Input bind:value={propertyDeduction} enabled={number === 4} type={'float'} postfix={'BYN'}>
      <span slot="label">Имущественный вычет</span>
      <svelte:fragment slot="tooltip">
        <p>Указывается годовая сумма расходов членов семьи, стоящих на учете нуждающихся в улучшении жилищных условий на приобретение, строительство, реконструкцию жилья. Подробнее: п. 1.1 – 1.2 ст. 211 НК РБ.</p>
        <p>Факт наличия у вас права на данный вычет подтверждается в ИМНС документально. Подробнее: п. 2 – 4 ст. 211 НК РБ.</p>
      </svelte:fragment>
    </Input>
  </div>
</section>

<Navigation>
  <Input bind:value={incomeDeduction} {customProperties} {toggleProperty} property={'incomeDeduction'} postfix={'BYN'} type={'float'}>
    <span slot="label">Вычет по уровню дохода</span>
    <svelte:fragment slot="tooltip">
    <p>Если ваши доходы минус расходы за квартал меньше, чем указано в п. 2 ст. 209 НК РБ, вам положен налоговый вычет.</p>
  </svelte:fragment>
  </Input>
  <Input bind:value={inferiorDeduction} {customProperties} {toggleProperty} property={'inferiorDeduction'} postfix={'BYN'} type={'float'}>
    <span slot="label">Вычет на детей/иждивенцев</span>
  </Input>
  <Input bind:value={specialStatusDeduction} {customProperties} {toggleProperty} property={'specialStatusDeduction'} postfix={'BYN'} type={'float'}>
    <span slot="label">Вычет отдельным категориям</span>
  </Input>
  <Input bind:value={totalDeduction} enabled={false} postfix={'BYN'} type={'float'}>
    <span slot="label">Итого вычета</span>
  </Input>
</Navigation>

<style lang="scss">
  .deductions {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: max-content;
    row-gap: 48px;
    padding: 16px 0;
    @media (max-width: 1279px) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, max-content); 
    }
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(4, max-content);
    }
    &__item {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      &:nth-child(1) {
        padding-right: 16px;
        border-right: 1px solid #E7E5E4; 
        @media (max-width: 768px) {
          border-right: none;
          padding-right: 0;
        }
      }
      &:nth-child(2) {
        padding-right: 16px;
        border-right: 1px solid #E7E5E4; 
        padding-left: 16px;
        @media (max-width: 1279px) {
          padding-right: 0;
          border-right: none;
        }
        @media (max-width: 768px) {
          padding-left: 0;
        }
      } 
      &:nth-child(3) {
        padding-right: 16px;
        border-right: 1px solid #E7E5E4; 
        padding-left: 16px;
        @media (max-width: 1279px) {
          padding-left: 0;
        }
        @media (max-width: 768px) {
          border-right: none;
          padding-right: 0;
        }
      }
      &:nth-child(4) {
        padding-left: 16px;
        @media (max-width: 768px) {
          padding-left: 0;
        }
      }
    }
    &__heading {
      color: #EF3124;
      font-size: 14px;
      line-height: 16px;
    }
    &__inputs {
      display: flex;
      gap: 16px;
    }
    &__toggles {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    &__toggle {
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
</style>