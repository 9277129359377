import * as Fetch from './fetch.module.js'

export const addLog = async (log) => Fetch.post('/api/logs', log)
export const saveSelectedTransactions = async (userNumber, transactions) => Fetch.put(`/api/users/${userNumber}/selections`, transactions)
export const getSelectedTransactions = async (userNumber) => Fetch.get(`/api/users/${userNumber}/selections`)
export const saveQuarters = async (userNumber, quarters) => Fetch.put(`/api/users/${userNumber}/quarters`, quarters)
export const getQuarters = async (userNumber) => Fetch.get(`/api/users/${userNumber}/quarters`)
export const saveUser = async (user) => Fetch.post(`/api/users/${user.number}`, user)
export const getUser = async (userNumber, source) => Fetch.get(`/api/users/${userNumber}?source=${source}`)
export const getLatestDeclaration = async (userNumber) => Fetch.get(`/api/users/${userNumber}/declarations/latest`)
export const getInspections = async () => Fetch.get(`/api/inspections`)
export const getActivities = async () => Fetch.get(`/api/activities`)
export const saveDeclaration = async (declaration) => Fetch.post(`/api/users/${declaration.user.number}/declarations`, declaration)
export const getDeclaration = async (declarationID) => Fetch.get(`/api/declarations/${declarationID}`)