export const sumArray = (array) => Number(array.reduce((previousValue, currentValue) => (previousValue * 100 + currentValue * 100) / 100, 0).toFixed(2))
export const subtract = (minuend, subtrahend) => Number((minuend - subtrahend).toFixed(2))
export const add = (augend, addend) => Number((augend + addend).toFixed(2))
export const divide = (dividend, divisor) => Number((dividend / divisor).toFixed(2))
export const calculateRegularTaxFromTaxBase = (taxBase) => Number((taxBase / 100 * 6).toFixed(2))
export const calculateSpecialTaxFromTaxBase = (taxBase) => Number((taxBase / 100 * 16).toFixed(2))
export const calculateTaxDeductionFromTaxBase = (taxBase) => Number((taxBase / 100 * 20).toFixed(2))
export const formatNumber = (number) => number.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
export const padNumberWithZero = (number) => String(number).padStart(2, '0')
export const sortArrayByDate = (array, datePropertyName) => array.sort((a, b) => new Date(b[datePropertyName]) - new Date(a[datePropertyName]))
export const filterTransactionsThatBelongToQuarterByQuarterNumberAndYear = (transactions, quarterNumber, year) => transactions.filter(transaction => transaction.date.year === year && transaction.date.quarter === quarterNumber)
export const filterIncomeTaxPaymentTransactions = (outgoingTransactions) => outgoingTransactions.filter(outgoingTransaction => outgoingTransaction.code === '00101')
export const filterSocialSecurityPaymentTransactions = (outgoingTransactions) => outgoingTransactions.filter(outgoingTransaction => outgoingTransaction.code === '03511')
export const getArrayOfTransactionAmounts = (transactions) => transactions.map(transaction => Number(transaction.amount.equivalent))

export const formatUserNameIntoInitials = (userName) => {
  const arrayOfInitials = userName.trim().split(' ').map((namePart, namePartIndex) => namePartIndex === 0 ? namePart : namePart.charAt(0))
  arrayOfInitials.push(arrayOfInitials.shift())
  const formattedUserName = arrayOfInitials.join('. ')
  return formattedUserName
}
export const generateHashFromString = function (str, seed = 0) {
  let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i)
    h1 = Math.imul(h1 ^ ch, 2654435761)
    h2 = Math.imul(h2 ^ ch, 1597334677)
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909)
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909)
  return 4294967296 * (2097151 & h2) + (h1 >>> 0)
}

export const leaveLatestData = (userProperties) => {
  if (!userProperties) return {}
  for (const userPropertyKey of Object.keys(userProperties)) {
    const userProperty = userProperties[userPropertyKey]
    const userPropertyIsNotAnArray = userProperty.constructor.name !== 'Array'
    if (userPropertyIsNotAnArray) continue
    const userProperyChildrenDoNotContainLastModified = !userProperty.every(userPropertyChild => userPropertyChild.lastModified)
    if (userProperyChildrenDoNotContainLastModified) {
      delete userProperties[userPropertyKey]
      continue
    }
    const userPropertyChildrenSortedByLastModifiedDate = sortArrayByDate(userProperty, 'lastModified')
    const mostRecentUserPropertyData = userPropertyChildrenSortedByLastModifiedDate[0].data
    userProperties[userPropertyKey] = mostRecentUserPropertyData
  }
  return userProperties
}

export const leaveOnlyLatestEntityPropertyData = (userProperties) => {
  if (!userProperties) return {}
  for (const userPropertyKey of Object.keys(userProperties)) {
    const userProperty = userProperties[userPropertyKey]
    const userPropertyIsNotAnArray = userProperty.constructor.name !== 'Array'
    if (userPropertyIsNotAnArray) continue
    const userProperyChildrenDoNotContainLastModified = !userProperty.every(userPropertyChild => userPropertyChild.updatedAt)
    if (userProperyChildrenDoNotContainLastModified) {
      delete userProperties[userPropertyKey]
      continue
    }
    const userPropertyChildrenSortedByLastModifiedDate = sortArrayByDate(userProperty, 'updatedAt')
    const mostRecentUserPropertyData = userPropertyChildrenSortedByLastModifiedDate[0].data
    userProperties[userPropertyKey] = mostRecentUserPropertyData
  }
  return userProperties
}

export const leaveUniqueArrayValues = (array) => [...new Set(array)]