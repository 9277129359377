<script>
  import { tooltip } from '../../modules/store.module'
  export let checked
  export let indeterminate = null
  export let onChange = null

  const openTooltip = ({ target }) => {
    const contents = target.querySelector('.checkbox__tooltip-contents').innerHTML
    const heading = target.closest('.checkbox').querySelector('.checkbox__label').innerText
    $tooltip = { heading, contents }
  }
</script>

<label class="checkbox">
  <input type="checkbox" class="checkbox__input" bind:checked {indeterminate} on:change={onChange}>
  <span class="checkbox__mark"></span>
  {#if $$slots.label}
    <span class="checkbox__label">
      <slot name="label" />
    </span>
  {/if}
  {#if $$slots.tooltip}
    <button class="checkbox__tooltip" on:click={openTooltip}>
      <div class="checkbox__tooltip-contents">
        <slot name="tooltip" />
      </div>
    </button>
    {/if}
</label>

<style lang="scss">
  .checkbox {
    display: flex;
    align-items: center;
    column-gap: 4px;
    &:has(:checked) {
      .checkbox__mark {
        background-color: #EF3124;
        border-color: #EF3124;
        background-image: url('/images/tick.svg');
      }
    }
    &:has(:indeterminate) {
      .checkbox__mark {
        background-color: #D1D5DB;
        border-color: #D1D5DB;
        background-image: url('/images/dash.svg');
      }
    }
    &__label {
      padding-left: 4px;
    }
    &__mark {
      display: inline-flex;
      cursor: pointer;
      min-width: 16px;
      height: 16px;
      border-radius: 8px;
      border: 1px solid #D1D5DB;
      background-repeat: no-repeat;
      background-position: center;
    }
    
    &__input {
      appearance: none;
      background-color: transparent;
      position: absolute;
    }
    &__tooltip {
      &:before {
        content: '?';
        user-select: none;
        width: 14px;
        height: 14px;
        color: white;
        display: flex;
        background-color: #A8A29F;
        border-radius: 8px;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        cursor: pointer;
        transition: opacity .3s ease-in-out;
        &:hover {
          opacity: .7;
        }
      }
      &-contents {
        display: none;
      }
    }
  }
</style>