<div class="flex items-center gap-2">
  <svg class="logomark" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M30.7134 5.2877C34.0945 8.66876 36.0006 13.2138 36.0006 17.9998H18.0002L30.7134 5.2877Z" fill="#EE3338"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0002 6.92151e-06C22.9506 6.92151e-06 27.4509 2.02519 30.7134 5.2877L18.0002 17.9998V6.92151e-06Z" fill="#B4363F"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 6.92151e-06H17.9998L18.0003 17.9998L0 6.92151e-06Z" fill="#EE3338"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 10.3033V6.79567e-05L18.0003 17.9999H7.69711L0 10.3033Z" fill="#B4363F"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M30.7134 30.7125C34.0945 27.3315 36.0006 22.7864 36.0006 17.9999H18.0002L30.7134 30.7125Z" fill="#B4363F"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0002 36C22.9506 36 27.4509 33.9754 30.7134 30.7123L18.0002 18.0002V36Z" fill="#D23238"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 36H17.9998L18.0003 18.0002L0 36Z" fill="#EE3338"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 25.6966V35.9999L18.0003 18.0001H7.69711L0 25.6966Z" fill="#D23238"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8401 17.5585L14.8874 15.5107L10.5751 11.1989H3.69873L10.0545 17.5585H12.8401Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5588 12.8394L15.511 14.8867L11.1992 10.5749V3.69855L17.5588 10.0543V12.8394Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5588 23.1606L15.511 21.1133L11.1992 25.425V32.3019L17.5588 25.9456V23.1606Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8401 18.4415L14.8874 20.4894L10.5751 24.8016H3.69873L10.0545 18.4415H12.8401Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M23.1608 18.4415L21.1135 20.4894L25.4253 24.8016H32.3022L25.9458 18.4415H23.1608Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.4421 23.1606L20.49 21.1133L24.8017 25.425V32.3019L18.4421 25.9456V23.1606Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.4421 12.8394L20.49 14.8867L24.8017 10.5749V3.69855L18.4421 10.0543V12.8394Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M23.1608 17.5585L21.1135 15.5107L25.4253 11.1989H32.3022L25.9458 17.5585H23.1608Z" fill="white"/>
  </svg>
  <svg class="logotype" width="99" height="32" viewBox="0 0 99 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.99731 0.734657H10.7641V2.73613H4.49566V4.97041H7.50595C8.81925 4.97041 9.81999 5.24471 10.5082 5.79277C11.1958 6.34137 11.5402 7.13194 11.5402 8.16717C11.5402 9.25305 11.157 10.0959 10.3918 10.6951C9.62599 11.2966 8.54549 11.5962 7.1492 11.5962H1.99731L1.99731 0.734657ZM7.00909 9.73429C7.65038 9.73429 8.14186 9.60765 8.48352 9.35383C8.82464 9.10054 8.99547 8.73086 8.99547 8.24477C8.99547 7.29253 8.33316 6.81669 7.00909 6.81669H4.49566V9.73429H7.00909Z" fill="#606163"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.8342 1.13781C21.579 1.53121 22.1583 2.11053 22.5722 2.87577C22.9855 3.64155 23.1924 4.56684 23.1924 5.65326V11.5963H20.679V8.98905H15.7146V11.5963H13.2319V5.65326C13.2319 4.56684 13.4383 3.64155 13.8517 2.87577C14.2655 2.11053 14.8449 1.53121 15.5896 1.13781C16.3349 0.744956 17.2085 0.548258 18.2125 0.548258C19.2153 0.548258 20.0894 0.744956 20.8342 1.13781ZM20.679 6.97249V5.42046C20.679 4.52103 20.4564 3.8404 20.0118 3.38018C19.5667 2.91996 18.9621 2.68985 18.1963 2.68985C17.4213 2.68985 16.8135 2.91996 16.3737 3.38018C15.9334 3.8404 15.7146 4.52103 15.7146 5.42046V6.97249H20.679Z" fill="#606163"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M35.6989 0.734657V11.5962H33.1855V7.14272H28.2513V11.5962H25.7368V0.734657H28.2513V5.01676H33.1855V0.734657H35.6989Z" fill="#606163"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M42.4787 7.22014H40.7407V11.596H38.2429V0.735013H40.7407V5.12543H42.5406L45.3963 0.735013H48.0493L44.4952 5.97959L48.1571 11.596H45.302L42.4787 7.22014Z" fill="#606163"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2811 27.2457V31.5435H10.9536V29.293H3.0091V31.5435H0.682129L0.697757 27.2457H2.00028V23.1803C2.00028 22.1359 2.20775 21.2429 2.62163 20.5035C3.03497 19.7647 3.61698 19.2026 4.36659 18.8195C5.11728 18.4374 5.98814 18.2461 6.98187 18.2461C7.97399 18.2461 8.84647 18.4374 9.59608 18.8195C10.3462 19.2026 10.9277 19.7647 11.3416 20.5035C11.7555 21.2429 11.9624 22.1359 11.9624 23.1803V27.2457H13.2811ZM9.43279 27.2457V22.9631C9.43279 22.1149 9.21831 21.473 8.78988 21.0387C8.35984 20.6049 7.75735 20.3866 6.98187 20.3866C6.2161 20.3866 5.61307 20.6049 5.17386 21.0387C4.73412 21.473 4.51425 22.1149 4.51425 22.9631V27.2457H9.43279Z" fill="#606163"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.5742 18.8276C23.3195 19.221 23.8988 19.8008 24.3127 20.5655C24.7266 21.3313 24.933 22.2566 24.933 23.343V29.286H22.4195V26.6788H17.4546V29.286H14.9719V23.343C14.9719 22.2566 15.1789 21.3313 15.5917 20.5655C16.0061 19.8008 16.5849 19.221 17.3302 18.8276C18.0744 18.4347 18.9485 18.2386 19.9524 18.2386C20.9559 18.2386 21.83 18.4347 22.5742 18.8276ZM22.4195 24.6622V23.1102C22.4195 22.2108 22.197 21.5302 21.7524 21.0699C21.3072 20.6103 20.7026 20.3796 19.9368 20.3796C19.1613 20.3796 18.5535 20.6103 18.1143 21.0699C17.674 21.5302 17.4546 22.2108 17.4546 23.1102V24.6622H22.4195Z" fill="#606163"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M27.4775 18.4244H36.2444V20.4264H29.9759V22.6602H32.9862C34.2989 22.6602 35.3002 22.9345 35.9884 23.4825C36.676 24.0311 37.0204 24.8222 37.0204 25.8569C37.0204 26.9434 36.6372 27.7857 35.872 28.3854C35.1057 28.9863 34.0263 29.2859 32.6289 29.2859H27.4775V18.4244ZM32.4899 27.424C33.1306 27.424 33.6221 27.2979 33.9632 27.0436C34.3049 26.7903 34.4752 26.4206 34.4752 25.9351C34.4752 24.9828 33.8139 24.507 32.4899 24.507H29.9759V27.424H32.4899Z" fill="#606163"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M47.021 19.4254C47.8736 20.1701 48.3009 21.215 48.3009 22.5591C48.3009 23.8314 47.9151 24.83 47.1444 25.5543C46.3738 26.2786 45.296 26.6407 43.91 26.6407C43.4546 26.6407 43.0121 26.5938 42.5826 26.5006C42.1537 26.4074 41.6908 26.2527 41.1939 26.0355V29.2781H38.7112V22.8544C38.7112 21.395 39.1175 20.2736 39.9296 19.4873C40.7412 18.7011 41.9182 18.3082 43.4605 18.3082C44.9802 18.3082 46.1669 18.6801 47.021 19.4254ZM45.1737 24.1887C45.5725 23.8163 45.7713 23.2882 45.7713 22.606C45.7713 21.9124 45.5725 21.3751 45.1737 20.9925C44.7754 20.6099 44.2257 20.4186 43.5209 20.4186C42.7874 20.4186 42.2156 20.6303 41.8072 21.0545C41.3981 21.4791 41.1939 22.0735 41.1939 22.8388V24.0497C41.5048 24.2663 41.8589 24.4371 42.2566 24.5616C42.6549 24.6856 43.0612 24.7475 43.4751 24.7475C44.209 24.7475 44.7754 24.5616 45.1737 24.1887Z" fill="#606163"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M57.5942 18.8049C58.3395 19.1978 58.9183 19.7776 59.3322 20.5429C59.7455 21.3086 59.953 22.2334 59.953 23.3204V29.2628H57.439V26.6562H52.4741V29.2628H49.9919V23.3204C49.9919 22.2334 50.1983 21.3086 50.6117 20.5429C51.0261 19.7776 51.6049 19.1978 52.3502 18.8049C53.0944 18.4121 53.9679 18.2154 54.9725 18.2154C55.9754 18.2154 56.85 18.4121 57.5942 18.8049ZM57.439 24.6391V23.087C57.439 22.1876 57.217 21.5075 56.7724 21.0467C56.3267 20.5871 55.7226 20.357 54.9563 20.357C54.1808 20.357 53.5729 20.5871 53.1337 21.0467C52.694 21.5075 52.4741 22.1876 52.4741 23.087V24.6391H57.439Z" fill="#606163"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M62.4976 18.4013H71.2649V20.4034H64.9959V22.6371H68.0067C69.3195 22.6371 70.3202 22.9114 71.0079 23.46C71.696 24.0081 72.0399 24.7992 72.0399 25.8339C72.0399 26.9203 71.6572 27.7631 70.892 28.3624C70.1262 28.9632 69.0452 29.2629 67.6494 29.2629H62.4976V18.4013ZM67.5093 27.401C68.1506 27.401 68.6416 27.2749 68.9832 27.0205C69.3249 26.7678 69.4957 26.3975 69.4957 25.912C69.4957 24.9598 68.8334 24.4839 67.5093 24.4839H64.9959V27.401H67.5093Z" fill="#606163"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M82.1801 22.6841C82.94 23.3572 83.3199 24.2975 83.3199 25.5074C83.3199 26.7706 82.8958 27.7476 82.0486 28.4412C81.1999 29.1336 79.9944 29.4796 78.4332 29.4796C76.8914 29.4796 75.723 29.1336 74.926 28.4412C74.129 27.7476 73.7307 26.7291 73.7307 25.3845V18.4025H76.214V22.3117C77.1964 21.887 78.1535 21.6758 79.0847 21.6758C80.3877 21.6758 81.4197 22.0115 82.1801 22.6841ZM80.2013 27.0131C80.5947 26.6714 80.7908 26.1966 80.7908 25.5861C80.7908 24.9555 80.5818 24.4662 80.1625 24.1192C79.7438 23.7727 79.1882 23.5997 78.4941 23.5997C78.1114 23.5997 77.7191 23.66 77.3155 23.778C76.9118 23.8971 76.5448 24.0647 76.214 24.2825V25.322C76.214 26.0258 76.4155 26.569 76.8192 26.9511C77.2222 27.3343 77.7967 27.5256 78.5409 27.5256C79.255 27.5256 79.8079 27.3547 80.2013 27.0131ZM87.1682 29.263H84.686V18.402H87.1682V29.263Z" fill="#606163"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M98.2775 20.45H94.8021V29.2632H92.3189V20.45H88.8591V18.4022H98.2775V20.45Z" fill="#606163"/>
  </svg>
</div>

<style lang="scss">
  .logotype {
    @media (max-width: 768px) {
      display: none;
    }
  }
</style>