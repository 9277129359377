<footer class="footer">
  <div class="footer__container">
    <span class="footer__text footer__text--type_company">ООО «Блэк Рокет»</span>
    <span class="footer__text footer__text--type_secondary">По вопросам, касающимся сервиса, вы можете обратиться: <a href="tel:+375292519334" class="footer__link">+375 (29) 251‑93‑34</a>, <a class="footer__link footer__link--type_web" href="mailto:support@bittax.by">support@bittax.by</a></span>
  </div>
</footer>

<style lang="scss">
  .footer {
    width: 100%;
    background-color: black;
    height: 36px;
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
      height: 84px;
    }
    &__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      line-height: 20px;
      column-gap: 32px;
      box-sizing: border-box;
      max-width: 1280px;
      padding: 4px 16px;
      width: 100%;
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 8px;
      }
    }
    &__text {
      color: white;
      &--type_company {
        white-space: nowrap;
      }
      &--type_secondary {
        color: rgba(255, 255, 255, 0.5);
      }
    }
    &__link {
      transition: opacity .3s ease-in-out;
      white-space: nowrap;
      color: white;
      &:hover {
        opacity: 0.7;
      }
    }
  }
</style>