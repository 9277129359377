<div class="alfa-logo">
  <svg width="21" height="32" viewBox="0 0 21 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.0273 32H0V27.6306H21.0273V32Z" fill="#EF3124"/>
    <path d="M7.50962 13.5842L10.4589 4.81833H10.5682L13.3535 13.5842H7.50962ZM14.3356 3.2002C13.7361 1.41222 13.0451 -1.53376e-05 10.6775 -1.53376e-05C8.30962 -1.53376e-05 7.57432 1.40654 6.94329 3.2002L0.437073 21.6946H4.7516L6.25367 17.2981H14.5551L15.9479 21.6946H20.5358L14.3356 3.2002Z" fill="#EF3124"/>
  </svg>
  <svg class="logotype" width="139" height="32" viewBox="0 0 139 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.34848 14.6494C2.25449 17.516 1.88422 18.0902 0.273071 18.0902V21.6948H0.873726C4.89295 21.6948 5.85951 19.6192 6.0077 15.0043L6.17164 9.89769H9.93993V21.6948H13.8726V6.32043H2.62148L2.34848 14.6494Z" fill="#EF3124"/>
    <path d="M23.3462 18.3086H20.8065V14.4035H23.3462C24.7114 14.4035 25.5306 15.0043 25.5306 16.2876C25.5306 17.6804 24.7387 18.3086 23.3462 18.3086ZM23.6465 10.9902H20.8065V6.32022H16.8743V21.6948H23.7011C27.9886 21.6948 29.5177 18.9093 29.5177 16.2876C29.5177 12.9016 27.3877 10.9902 23.6465 10.9902Z" fill="#EF3124"/>
    <path d="M42.3251 18.336V9.65208C44.5098 9.9524 45.8753 11.5909 45.8753 13.9939C45.8753 16.397 44.5098 18.0354 42.3251 18.336ZM38.3929 18.336C36.2082 18.0354 34.843 16.397 34.843 13.9939C34.843 11.5909 36.2082 9.9524 38.3929 9.65208V18.336ZM42.3251 5.96531V0.257781H38.3929V5.96531C33.8871 6.34782 30.8557 9.54279 30.8557 13.9939C30.8557 18.4726 33.8871 21.6676 38.3929 22.0499V27.8394H42.3251V22.0499C46.8309 21.6949 49.8622 18.4726 49.8622 13.9939C49.8622 9.51546 46.8309 6.32028 42.3251 5.96531Z" fill="#EF3124"/>
    <path d="M60.6764 15.96C60.6764 17.7351 59.4202 18.7456 57.7817 18.7456C56.3616 18.7456 55.2421 18.2267 55.2421 16.6973C55.2421 15.1954 56.4709 14.9497 57.536 14.9497H60.6764V15.96ZM64.6086 16.9705V11.8092C64.6086 8.04068 62.2055 5.66473 58.164 5.66473C53.9859 5.66473 51.7739 8.17729 51.5826 10.7987H55.5697C55.7063 10.2252 56.3072 9.24221 58.164 9.24221C59.6932 9.24221 60.6764 9.95237 60.6764 11.8092H56.7712C53.2759 11.8092 51.2003 13.6387 51.2003 16.6973C51.2003 19.8925 53.4707 22.0771 56.7439 22.0771C59.1441 22.0771 60.4766 20.9427 61.0587 20.0837C61.5773 21.1211 62.7244 21.6946 64.2263 21.6946H65.5918V18.1447C64.8818 18.1447 64.6086 17.817 64.6086 16.9705Z" fill="#EF3124"/>
    <path d="M133.538 6.36121L128.104 12.4508V6.36121H124.172V21.7356H128.104V15.2637L133.921 21.7356H139L131.845 13.6799L138.481 6.36121H133.538Z" fill="#EF3124"/>
    <path d="M117.494 12.0548H112.06V6.32019H108.127V21.6946H112.06V15.6323H117.494V21.6946H121.426V6.32019H117.494V12.0548Z" fill="#EF3124"/>
    <path d="M82.2668 17.817H78.0614V12.2461H82.2668C84.4513 12.2461 85.6801 13.2566 85.6801 15.0315C85.6801 16.8612 84.4513 17.817 82.2668 17.817ZM82.4578 8.50491H78.0614V4.16306H88.2471V0.257935H73.9379V21.6948H82.4578C87.1822 21.6948 89.8856 19.3464 89.8856 15.0315C89.8856 10.9628 87.1822 8.50491 82.4578 8.50491Z" fill="#EF3124"/>
    <path d="M101 15.96C101 17.7351 99.7439 18.7456 98.1054 18.7456C96.6855 18.7456 95.5657 18.2267 95.5657 16.6973C95.5657 15.1954 96.7946 14.9497 97.8597 14.9497H101V15.96ZM104.932 16.9705V11.8092C104.932 8.04068 102.529 5.66473 98.4877 5.66473C94.3096 5.66473 92.0975 8.17729 91.9063 10.7987H95.8934C96.03 10.2252 96.6309 9.24221 98.4877 9.24221C100.017 9.24221 101 9.95237 101 11.8092H97.0949C93.5996 11.8092 91.524 13.6387 91.524 16.6973C91.524 19.8925 93.7946 22.0771 97.0676 22.0771C99.468 22.0771 100.8 20.9427 101.382 20.0837C101.901 21.1211 103.048 21.6946 104.55 21.6946H105.915V18.1447C105.205 18.1447 104.932 17.817 104.932 16.9705Z" fill="#EF3124"/>
  </svg>
</div>

<style lang="scss">
  .alfa-logo {
    display: flex;
  }
  .logotype {
    @media (max-width: 768px) {
      display: none;
    }
  }
</style>