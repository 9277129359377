import { DateTime } from 'luxon'
import BigNumber from './bignumber.module'
import * as Time from './time.module'
import { get } from 'svelte/store'
import { parse } from 'cookie'
import * as Fetch from './fetch.module'

import * as Utilities from './utilities.module'
import * as Store from './store.module'

const baseURL = 'https://api.priorbank.by:9344'

const getAuthHeader = () => ({ 'Authorization': `Bearer ${parse(document.cookie).access_token}` })

const getDocuments = async () => {
  const dateTo = DateTime.now().toFormat('yyyy-MM-dd') + 'T00:00:00'
  const requestURL = `${baseURL}/PaymentDocuments/v4/documents?dateFrom=2020-01-01T00:00:00&dateTo=${dateTo}&type=9&showFullInfo=true`
  return Fetch.get(requestURL, getAuthHeader())
}

const getUserNumberFromDocument = async (document) => new Promise(async (resolve, reject) => {
  try {
    const { firstName, lastName, patronymicName, document_09 } = document
    const number = document_09.source.sourceUNN
    const name = `${lastName} ${firstName}${patronymicName ? ' ' + patronymicName : ''}`
    return resolve({ name, number })
  }
  catch (e) {
    console.error(e)
    return reject(e.message)
  }
})

export const getUserProperties = async () => {
  const { data: documents } = await getDocuments()
  if (!documents) return
  let user = await Promise.any(documents.map(getUserNumberFromDocument))
  user.bank = 'prior'
  return user
}

const formatAccount = ({ iban: number, rest: amount, id, currency: code }) => ({ number, type: 'Текущий', amount, id, currency: { code, ISO: get(Store.currencyISOMap).get(Number(code)) } })

export const getAccounts = async () => {
  const requestURL = `${baseURL}/account/v1/accounts?fields=rest,%20restNC,%20closed`
  const { data: accounts } = await Fetch.get(requestURL, getAuthHeader())
  return accounts ? accounts.map(formatAccount) : null
}

const formatTransaction = (transactionAccount) => (transaction) => {
  const transactionDate = DateTime.fromFormat(transaction.date, 'yyyy-MM-dd hh:mm:ss', { zone: 'Europe/Minsk' })
  const transactionUnixInteger = transactionDate.toUnixInteger()
  const transactionKind = transaction.amount.debitAmount === 0 ? 'incoming' : 'outgoing'
  const transactionAmount = {
    equivalent: transactionKind === 'incoming' ? transaction.amount.creditAmountNC : transaction.amount.debitAmountNC,
    original: transactionKind === 'incoming' ? transaction.amount.creditAmount : transaction.amount.debitAmount
  }
  return {
    hash: Utilities.generateHashFromString(`${transactionUnixInteger}.${transactionKind}.${transaction.description}.${transactionAmount.equivalent}`),
    active: false,
    special: false,
    kind: transactionKind,
    date: transactionDate,
    purpose: transaction.description,
    account: {
      number: transactionAccount.number,
      bank: {
        code: 'UNBSBY2X'
      }
    },
    amount: transactionAmount,
    correspondent: {
      name: transaction.customerName,
      number: transaction.customerTaxNumber,
      account: {
        number: transaction.iban,
        bank: {
          name: transaction.correspondentBankName,
          code: transaction.bankCode
        }
      }
    },
    currency: transactionAccount.currency,
    ...(transaction.id) && {
      document: {
        ...(transaction.id) && { id: transaction.id }
      }
    },
    ...(transaction.taxCode && { code: transaction.taxCode })
  }
}

const getStatement = async (accountId, interval) => {
  let [dateFrom, dateTo] = interval
  const today = DateTime.now()
  if (dateTo.startOf('day') > today.startOf('day')) dateTo = today
  const requestURL = `${baseURL}/account/v1/transactions?dateFrom=${dateFrom.toFormat('yyyy-MM-dd')}&dateTo=${dateTo.toFormat('yyyy-MM-dd')}&accounts=${accountId}`
  const response = await Fetch.get(requestURL, getAuthHeader())
  return response
}

const getAccountTransactionsForIntervals = async (account, intervals) => {
  let transactions = []
  for (const interval of intervals) {
    const statement = await getStatement(account.id, interval)
    if (!statement) continue
    const statementTransactions = statement.data?.[0]?.payments
    if (!statementTransactions) continue
    const formattedStatementTransactions = statementTransactions.map(formatTransaction(account))
    transactions = [...transactions, ...formattedStatementTransactions]
  }
  return transactions
}

const sortByDate = (a, b) => a.date < b.date ? -1 : a.date > b.date ? 1 : 0

export const getTransactionsFromAccounts = async (accounts) => {
  const intervals = Time.generatePeriodIntervals()
  let transactions = []
  for (const account of accounts) {
    const accountTransactions = await getAccountTransactionsForIntervals(account, intervals)
    transactions = [...transactions, ...accountTransactions]
  }
  return transactions.sort(sortByDate)
}


export const payTax = async (declaration, declarationId) => {
  const paymentOrderId = await createPaymentOrderWithPeriodDataAndGetItsId(declaration)
  window.location = `/declarations/${declarationId}`
}


const createPaymentOrderWithPeriodDataAndGetItsId = async (declaration) => {
  const { rate, user, quarter } = declaration
  const { number, year, paymentDate } = quarter
  const allInspections = get(Store.inspections)
  const specifiedInspectionCode = user.inspection.code
  const specifiedInspection = allInspections.find(inspection => inspection.code == specifiedInspectionCode)
  const accounts = get(Store.accounts)
  const selectedAccount = accounts.find(account => account.active) || accounts.find(account => account.currency.ISO === 'BYN')
  const purposeText = rate == 'УСН' ? 'налога при УСН' : 'подоходного налога'
  const budgetCode = rate == 'УСН' ? '01201' : '00102'
  const paymentAmount = declaration.payment.actual
  const expectedAmount = declaration.payment.expected
  const paymentOrderData = {
    meta: {
      type: '9'
    },
    data: {
      document_09: {
        documentDate: Time.currentDateTimeInBelarus.toFormat('dd.MM.yyyy'),
        documentNumber: '1234567',
        instructionCode: '0401600036',
        amount: BigNumber(paymentAmount).toNumber(),
        executionDate: Time.currentDateTimeInBelarus.toFormat('dd.MM.yyyy'),
        transferCosts: 'OUR',
        purpose: {
          paymentPurpose: `Уплата ${purposeText} (${expectedAmount} BYN) за ${number} квартал ${year} года по сроку уплаты ${paymentDate}`,
          paymentCode: 'TAXS',
          paymentSing: '1',
          purposeCode: '90101'
        },
        paramEx: {
          paymentPriority: '13',
          budgetPaymentCode: budgetCode,
          address: user.address,
          person1FullName: user.name,
          person2FullName: user.name,
          person1Position: ' ',
          person2Position: ' ',
          settlementAcc: selectedAccount.number,
          city: ' '
        },
        source: {
          payerName: user.title,
          sourceUNN: user.number,
          isLE: '1',
          sourceAcc: selectedAccount.number,
          sourceBankName: '"ПРИОРБАНК" ОАО',
          sourceBIC: 'PJCBBY2X',
        },
        payee: {
          payeeUNN: specifiedInspection.beneficiary.number,
          payeeName: specifiedInspection.beneficiary.name,
          payeeAcc: specifiedInspection.beneficiary.account.number,
          payeeBankName: specifiedInspection.beneficiary.account.bank.name,
          payeeBIC: specifiedInspection.beneficiary.account.bank.code,
          payeeStatus: 'INN',
          payeeCountry: 'BY',
          isThirdPartyPayee: '1',
          actualPayee: {
            unn: specifiedInspection.receiver.number,
            status: 'INN',
            name: specifiedInspection.receiver.name,
            country: 'BY'
          }
        }
      }
    }
  }
  const { data: { id: paymentOrderId } } = (await Fetch.post(`${baseURL}/PaymentDocuments/v4/documents`, paymentOrderData, getAuthHeader()))
  return paymentOrderId
}
