import * as Alfa from './alfa.module'
import * as BSB from './bsb.module'
import * as Dabrabyt from './dabrabyt.module'
import * as Prior from './prior.module'

export const getAccounts = async (bank) => {
  const accounts = await getAccountsFromBank(bank)
  const accountsWithSelection = selectFirstAccountInBYN(accounts)
  return accountsWithSelection
}

const getAccountsFromBank = async (bank) => {
  if (bank === 'alfa') return Alfa.getAccounts()
  if (bank === 'bsb') return BSB.getAccounts()
  if (bank === 'dabrabyt') return Dabrabyt.getAccounts()
  if (bank === 'prior') return Prior.getAccounts()
}

const selectFirstAccountInBYN = (accounts) => {
  const firstAccountInBYN = accounts.find(({ currency }) => currency.ISO === 'BYN')
  if (!firstAccountInBYN) return accounts
  return accounts.map(account => ({ ...account, ...(account.number === firstAccountInBYN.number) && { active: true } }))
}