import { Buffer } from 'buffer'
import { DateTime } from 'luxon'
import { get } from 'svelte/store'
import { parse } from 'cookie'
import * as Time from './time.module'

import * as Utilities from './utilities.module'
import * as Store from './store.module'

const currentDateTimeInBelarus = DateTime.now().setZone('Europe/Minsk')
const firstDayOfReportingYear = currentDateTimeInBelarus.minus({ quarters: 1 }).startOf('year')
const sameDayYearAgo = currentDateTimeInBelarus.minus({ years: 1 }).startOf('day')
const dateToGetTransactionsFrom = sameDayYearAgo < firstDayOfReportingYear ? firstDayOfReportingYear : sameDayYearAgo

export const isTokenValid = async () => {
  const accounts = await getAccounts()
  return accounts ? true : false
}

export const getUserProperties = async () => {
  const accounts = await getAccounts()
  const dateFrom = Time.yearAgo.toFormat('yyyyMMdd')
  const transactions = await getTransactionsFromAccounts(accounts, dateFrom)
  const transactionsWithDocumentID = transactions.filter(transaction => transaction.document?.id)
  for (const transaction of transactionsWithDocumentID) {
    const transactionPrintForm = await getTransactionPrintForm(transaction)
    if (!transactionPrintForm) continue
    const userNumberRegex = /\\ul0 ([0-9]{9})\\cell/g
    const userNumberMatches = [...transactionPrintForm.matchAll(userNumberRegex)]
    const userNumber = transaction.kind === 'outgoing' ? userNumberMatches[0]?.[1] : userNumberMatches[1]?.[1]
    const BSBNumber = '807000069'
    if (!userNumber || userNumber === BSBNumber) continue
    const user = { number: userNumber }
    return user
  }
}

const getTransactionPrintForm = async (transaction) => {
  try {
    const accessToken = parse(document.cookie).access_token
    const transactionAccountNumber = transaction.account.number
    const transactionAccountNumberCurrencyCode = transactionAccountNumber.substr(transactionAccountNumber.length - 3)
    const transactionDocumentID = transaction.document.id
    const transactionDate = transaction.date.toFormat('yyyyMMdd')
    const requestURL = `/proxy/bsb/accounts/${transactionAccountNumber}/${transactionAccountNumberCurrencyCode}/printform/${transactionDocumentID}`
    const data = {
      token: accessToken,
      date: transactionDate
    }
    const request = await fetch(requestURL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
    if (!request.ok) return false
    const response = await request.json()
    const transactionPrintForm = Buffer.from(response.data.printForm, 'base64').toString()
    return transactionPrintForm
  }
  catch (e) {
    return false
  }
}

export const getAccounts = async () => {
  const accessToken = parse(document.cookie).access_token
  const requestURL = `/proxy/bsb/accounts`
  const data = {
    token: accessToken
  }
  const request = await fetch(requestURL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
  if (!request.ok) return false
  const response = await request.json()
  const unformattedAccounts = response.data
  const formattedAccounts = formatAccounts(unformattedAccounts)
  return formattedAccounts
}

const formatAccounts = (unformattedAccounts) => {
  let formattedAccounts = []
  for (const { description, code, amount, number, title: type, currency = { code, ISO: description } } of unformattedAccounts) {
    const formattedAccount = { number, type, amount, currency }
    formattedAccounts.push(formattedAccount)
  }
  return formattedAccounts
}

export const getTransactionsFromAccounts = async (accounts, fromDate) => {
  fromDate ??= dateToGetTransactionsFrom.toFormat('yyyyMMdd')
  let unformattedTransactions = []
  for (const account of accounts) {
    const accessToken = parse(document.cookie).access_token
    const requestData = {
      token: accessToken,
      fromDate,
      toDate: currentDateTimeInBelarus.toFormat('yyyyMMdd')
    }
    const requestURL = `/proxy/bsb/accounts/${account.number}/${account.currency.code}/statement`
    const request = await fetch(requestURL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(requestData)
    })
    if (!request.ok) return
    const response = await request.json()
    let { transactions } = response.data
    transactions = transactions.map(transaction => ({ ...transaction, account: account.number }))
    unformattedTransactions = [...unformattedTransactions, ...transactions]
  }
  const formattedTransactions = formatTransactions(unformattedTransactions)
  return formattedTransactions
}

const formatTransactions = (unformattedTransactions) => {
  let formattedTransactions = []
  for (const unformattedTransaction of unformattedTransactions) {
    const transactionDate = DateTime.fromISO(unformattedTransaction.docDateString, { zone: 'Europe/Minsk' })
    const transactionUnixInteger = transactionDate.toUnixInteger()
    const transactionKind = unformattedTransaction.debet === 0 ? 'incoming' : 'outgoing'
    const transactionAmount = {
      equivalent: transactionKind === 'incoming' ? unformattedTransaction.credit1 : unformattedTransaction.debet1,
      original: transactionKind === 'incoming' ? unformattedTransaction.credit : unformattedTransaction.debet
    }
    const transactionPurpose = unformattedTransaction.description
    const transactionAccountNumber = unformattedTransaction.account
    const transactionCurrencyCode = unformattedTransaction.currency
    const formattedTransaction = {
      hash: Utilities.generateHashFromString(`${transactionUnixInteger}.${unformattedTransaction.docId}.${transactionKind}.${transactionPurpose}.${transactionAmount.equivalent}`),
      active: false,
      special: false,
      kind: transactionKind,
      date: transactionDate,
      purpose: transactionPurpose,
      account: {
        number: transactionAccountNumber,
        bank: {
          code: 'UNBSBY2X'
        }
      },
      amount: transactionAmount,
      correspondent: {
        name: unformattedTransaction.correspondentName,
        number: unformattedTransaction.correspondentUnn,
        account: {
          number: unformattedTransaction.correspondent,
          bank: {
            // name: unformattedTransaction.corrBank, TODO: Подумать где взять наименование банка, поскольку в API его нет
            code: unformattedTransaction.correspondentBankCode
          }
        }
      },
      currency: {
        code: transactionCurrencyCode,
        ISO: get(Store.currencyISOMap).get(Number(transactionCurrencyCode))
      },
      ...(unformattedTransaction.docId && { document: { id: unformattedTransaction.docId } }),
      ...(unformattedTransaction.payC && { code: unformattedTransaction.payC })
    }
    formattedTransactions.push(formattedTransaction)
  }
  return formattedTransactions.reverse()
}