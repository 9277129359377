<script>
  import BigNumber from '../modules/bignumber.module'
  export let transaction
  export let showEquivalent
  import Checkbox from './controls/checkbox.svelte'
  export let activeTransactionHash
  
  $: deselected = !transaction.selected
  
  const toggleModification = () => transaction.modified = !transaction.modified
  transaction.setSelection = (selection) => {
    transaction.selected = selection
    toggleModification()
  }
  const toggleTransactionSelection = () => {
    activeTransactionHash = activeTransactionHash === transaction.hash ? '' : transaction.hash
  }

</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="transaction" class:transaction--active={transaction.hash === activeTransactionHash} on:click|self={toggleTransactionSelection}>
  <Checkbox bind:checked={transaction.selected} onChange={toggleModification}/>
  <div class="transaction__details" class:transaction__details--active={transaction.hash === activeTransactionHash} on:click={toggleTransactionSelection}>
    <div class="transaction__date" class:deselected={deselected}>{transaction.date.toFormat('dd.MM')}</div>
    <div class="transaction__correspondent" class:deselected={deselected}>{transaction.correspondent.name}</div>
    <div class="transaction__purpose" class:deselected={deselected}>{transaction.purpose}</div>
    <div class="transaction__value">
      <div class="transaction__amount" class:deselected={deselected}>{showEquivalent ? BigNumber(transaction.amount.equivalent).toFormat(2) : BigNumber(transaction.amount.original).toFormat(2)}</div>
      <div class="transaction__currency" class:deselected={deselected}>{showEquivalent ? 'BYN' : transaction.currency.ISO}</div>
    </div>
  </div>
</div>

<style lang="scss">
  .transaction {
    user-select: none;
    cursor: pointer;
    display: grid;
    grid-template-columns: 16px 1fr;
    padding: 16px;
    column-gap: 16px;
    &:not(:last-child) {
      border-bottom: 1px solid #E7E5E4;
    }
    &--active {
      background-color: #E7E5E4;
    }
    &__details {
      display: grid;
      gap: 8px;
      grid-template-areas: "date correspondent purpose value";
      grid-template-columns: 30px max-content auto max-content;
      overflow-x: hidden;
      @media (max-width: 459px) {
        grid-template-columns: 30px 1fr max-content;
      }
      &--active {
        grid-template-areas: 
          "date correspondent value"
          "purpose purpose purpose";
        grid-template-columns: 30px 1fr max-content;
        & .transaction__purpose {
          white-space: pre-wrap;
        }
        @media (max-width: 459px) {
          .transaction__purpose {
            display: initial;
          }
        }
      }
      
    }
    &__date {
      grid-area: date;
      font-size: 12px;
      color: #A8A29F;
      line-height: 16px;
    }
    &__correspondent {
      grid-area: correspondent;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      line-height: 16px;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
    &__purpose {
      grid-area: purpose;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
      font-size: 12px;
      color: #A8A29F;
      text-transform: uppercase;
      line-height: 16px;
      @media (max-width: 459px) {
        display: none;
      }
    }
    &__value {
      display: grid;
      grid-area: value;
      grid-template-columns: 1fr 25px;
      gap: 4px;
    }
    &__amount {
      font-size: 14px;
      line-height: 16px;
    }
    &__currency {
      font-size: 12px;
      color: #A8A29F;
      line-height: 16px;
    }
  }
  .deselected {
    color: #A8A29F;
    text-decoration: line-through;
  }
</style>