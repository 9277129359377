<script>
  import { onboarding } from '../../modules/store.module'
  export let inversed = false
  export let shifted = false
</script>

<div class="hint" class:hint--shifted={shifted}> <!--class:hint--inversed={inversed} -->
  <slot></slot> <button class="link" on:click={() => $onboarding.next()}>Продолжить</button>  
</div>

<style lang="scss">
  .hint {
    position: absolute;
    left: calc(50% - 80px);
    font-size: 12px;
    background-color: $black;
    font-weight: 400;
    color: white;
    border-radius: 8px;
    padding: 8px;
    max-width: 160px;
    z-index: 10;
    bottom: 32px;
    &:after {
      content: '▼';
      position: absolute;
      color: $black;
      bottom: -11px;
      left: 72px;
    }
    &--shifted {
      bottom: 96px;
    }
  }
  .link {
    display: inline;
    appearance: none;
    background: none;
    border: none;
    padding: 0;
    text-decoration: underline;
  }
</style>