import { DateTime } from 'luxon'
import BigNumber from './bignumber.module'
import * as Time from './time.module'
import { get } from 'svelte/store'
import { parse } from 'cookie'
import * as Fetch from './fetch.module'

import * as Utilities from './utilities.module'
import * as Store from './store.module'

const baseURL = 'https://ibankapi.bankdabrabyt.by:8243/api/v1'

const getAuthHeader = () => ({ 'Authorization': `Bearer ${parse(document.cookie).access_token}` })

export const getUserProperties = async () => {
  const requestURL = `${baseURL}/info/getClientInfo`
  const { clientInfo: unformattedUser } = await Fetch.get(requestURL, getAuthHeader())
  return formatUser(unformattedUser)
}

const isContained = (containingString) => (containedString) => containingString.toLowerCase().trim().split(' ').includes(containedString)

const formatUser = ({ clientName, unp, address, activityKindOKED }) => {
  const entityIndicators = ['ооо', 'чуп', 'чп', 'частное', 'общество']
  const userIsEntity = entityIndicators.some(isContained(clientName))
  const formattedUser = {
    bank: 'dabrabyt',
    number: unp,
    ...(address) && { address },
    type: {
      code: userIsEntity ? 1 : 2,
      name: userIsEntity ? 'Юридическое лицо' : 'Индивидуальный предприниматель',
    },
    ...(clientName) && { title: clientName },
    ...(activityKindOKED) && {
      activity: {
        code: activityKindOKED,
      }
    }
  }
  return formattedUser
}

const formatAccount = ({ accountNumber: number, balance: amount, currencyCode: code, currencyIso: ISO }) => ({ number, type: 'Текущий', amount, currency: { code, ISO } })

export const getAccounts = async () => {
  const requestURL = `${baseURL}/accounts/balance`
  const { accounts } = await Fetch.get(requestURL, getAuthHeader())
  return accounts ? accounts.map(formatAccount) : null
}

const getStatementByInterval = ([dateFrom, dateTo]) => async ({ number }) => {
  const requestURL = `${baseURL}/statements/${number}?dateFrom=${dateFrom.toFormat('dd.MM.yyyy')}&dateTo=${dateTo.toFormat('dd.MM.yyyy')}&pageSize=10000`
  return Fetch.get(requestURL, getAuthHeader())
}

const getStatementsByInterval = (accounts) => (interval) => accounts.map(getStatementByInterval(interval))

const formatTransaction = (statement, accounts) => (transaction) => {
  const transactionAccount = accounts.find(({ number }) => statement.accountNumber === number)
  const transactionDate = DateTime.fromFormat(transaction.acceptDate.split(' ')[0], 'dd.MM.yyyy', { zone: 'Europe/Minsk' })
  const transactionUnixInteger = transactionDate.toUnixInteger()
  const oldTransactionDate = DateTime.fromFormat(transaction.documentDate, 'dd.MM.yyyy', { zone: 'Europe/Minsk' })
  const oldTransactionUnixInteger = oldTransactionDate.toUnixInteger()
  const transactionKind = transaction.debitTurnover === 0 ? 'incoming' : 'outgoing'
  const transactionAmount = {
    equivalent: transactionKind === 'incoming' ? transaction.creditTurnoverEquivalent : transaction.debitTurnoverEquivalent,
    original: transactionKind === 'incoming' ? transaction.creditTurnover : transaction.debitTurnover
  }
  const transactionCurrencyCode = transaction.currency
  return {
    oldHash: Utilities.generateHashFromString(`${oldTransactionUnixInteger}.${transactionKind}.${transaction.paymentPurpose}.${transactionAmount.equivalent}`),
    hash: Utilities.generateHashFromString(`${transactionUnixInteger}.${transactionKind}.${transaction.paymentPurpose}.${transactionAmount.equivalent}`),
    active: false,
    special: false,
    kind: transactionKind,
    date: transactionDate,
    purpose: transaction.paymentPurpose,
    account: {
      number: transactionAccount.number,
      bank: {
        code: 'UNBSBY2X'
      }
    },
    amount: transactionAmount,
    correspondent: {
      name: transaction.correspondentName,
      number: transaction.correspondentUnn,
      account: {
        number: transaction.correspondentAccount,
        bank: {
          name: transaction.correspondentBankName,
          code: transaction.correspondentBankCode
        }
      }
    },
    currency: transactionAccount.currency,
    ...(transaction.documentId || transaction.printingFormId) && {
      document: {
        ...(transaction.documentId) && { id: transaction.documentId },
        ...(transaction.printingFormId) && { print: transaction.printingFormId }
      }
    },
    ...(transaction.budgetPayCode && { code: transaction.budgetPayCode })
  }
}

const formatTransactions = (accounts) => (statement) => statement.turnovers?.map(formatTransaction(statement, accounts))?.reverse() || []

export const getTransactionsFromAccounts = async (accounts) => {
  const intervals = Time.generatePeriodIntervals()
  const statements = await Promise.all(intervals.flatMap(getStatementsByInterval(accounts)))
  const transactions = statements.flatMap(formatTransactions(accounts))
  return transactions
}

export const payTax = async (declaration, declarationId) => {
  const paymentOrderId = await createPaymentOrderWithPeriodDataAndGetItsId(declaration)
  const paymentTicketId = await signPaymentOrderByPaymentOrderIdWithDeclarationIdAndGetItsTicketId(paymentOrderId, declarationId)
  redirectToTicketByPaymentTicketId(paymentTicketId)
}

const createPaymentOrderWithPeriodDataAndGetItsId = async (declaration) => {
  const { rate, user, quarter } = declaration
  const { number, year, paymentDate } = quarter
  const allInspections = get(Store.inspections)
  const specifiedInspectionCode = user.inspection.code
  const specifiedInspection = allInspections.find(inspection => inspection.code == specifiedInspectionCode)
  const accounts = get(Store.accounts)
  const selectedAccount = accounts.find(account => account.active) || accounts.find(account => account.currency.ISO === 'BYN')
  const purposeText = rate == 'УСН' ? 'налога при УСН' : 'подоходного налога'
  const budgetCode = rate == 'УСН' ? '01201' : '00102'
  const paymentAmount = declaration.payment.actual
  const expectedAmount = declaration.payment.expected
  const paymentOrderData = {
    parameters: [
      {
        name: 'DatePlt',
        value: Time.currentDateTimeInBelarus.toFormat('dd.MM.yyyy')
      },
      {
        name: 'N_plt',
        value: '00001'
      },
      {
        name: 'IsQuick',
        value: '0'
      },
      {
        name: 'ISO',
        value: '933'
      },
      {
        name: '004',
        value: BigNumber(paymentAmount).toNumber()
      },
      {
        name: 'MFO1',
        value: 'MMBNBY22'
      },
      {
        name: 'Bank1',
        value: 'ОАО \"Банк Дабрабыт\"'
      },
      {
        name: 'Acc',
        value: selectedAccount.number
      },
      {
        name: 'UNN',
        value: user.number
      },
      {
        name: 'Name',
        value: user.title
      },
      {
        name: 'SendAddress',
        value: user.address.substring(0, 100)
      },
      {
        name: 'SendBankCountry',
        value: '112'
      },
      {
        name: 'MFO2',
        value: specifiedInspection.beneficiary.account.bank.code
      },
      {
        name: 'Bank2',
        value: specifiedInspection.beneficiary.account.bank.name
      },
      {
        name: 'KorAcc',
        value: specifiedInspection.beneficiary.account.number
      },
      {
        name: 'UNNRec',
        value: specifiedInspection.beneficiary.number
      },
      {
        name: 'KorName',
        value: specifiedInspection.beneficiary.name
      },

      {
        name: 'ActualBenTypeCode',
        value: 'INN'
      },
      {
        name: 'ActualBenName',
        value: specifiedInspection.receiver.name
      },

      {
        name: 'ActualBenUNP',
        value: specifiedInspection.receiver.number
      },
      {
        name: 'NaznText',
        value: `Уплата ${purposeText} (${expectedAmount} BYN) за ${number} квартал ${year} года по сроку уплаты ${paymentDate}`
      },
      {
        name: 'OchPlat',
        value: '13'
      },
      {
        name: 'E',
        value: 'OUR'
      },
      {
        name: 'Plat1',
        value: budgetCode
      }
    ]
  }
  const { documentId: paymentOrderId } = (await Fetch.post(`${baseURL}/documents?accountNumber=${selectedAccount.number}&documentType=9`, paymentOrderData, getAuthHeader()))
  return paymentOrderId
}

const signPaymentOrderByPaymentOrderIdWithDeclarationIdAndGetItsTicketId = async (paymentOrderId, declarationId) => {
  const { ticket: paymentTicketId } = await Fetch.post(`${baseURL}/signature?documentId=${paymentOrderId}&redirect_uri=${encodeURIComponent(origin + '/declarations/' + declarationId + '?paid')}`, {}, getAuthHeader(), 'application/x-www-form-urlencoded')
  const paymentTicketHasNotBeenCreated = !Boolean(paymentTicketId)
  if (paymentTicketHasNotBeenCreated) return deletePaymentOrderByPaymentOrderId(paymentOrderId)
  return paymentTicketId
}

const deletePaymentOrderByPaymentOrderId = (paymentOrderId) => Fetch.del(`${baseURL}/documents/${paymentOrderId}`, getAuthHeader())



const redirectToTicketByPaymentTicketId = async (paymentTicketId) => {
  try {
    const requestURL = `/proxy/dabrabyt/signature?ticket=${paymentTicketId}`
    const { location } = await Fetch.get(requestURL, getAuthHeader())
    window.location.replace(location)
  }
  catch (e) {
    console.log(e)
  }
}