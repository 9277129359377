<script>
  import { tooltip } from '../../modules/store.module'
  export let checked
  export let onChange = null
  export let tooltipVisible = true

  const openTooltip = ({ target }) => {
    const contents = target.querySelector('.toggle__tooltip-contents').innerHTML
    const heading = target.parentNode.querySelector('.toggle__label').innerText
    $tooltip = { heading, contents }
  }

</script>

<div class="toggle">
  <label class="toggle__wrapper">
    <input type="checkbox" class="toggle__input" bind:checked on:change={onChange}>
  </label>
  <span class="toggle__label">
    <slot name="label" />
  </span>
  {#if $$slots.tooltip && tooltipVisible}
    <button class="toggle__tooltip" on:click={openTooltip}>
      <div class="toggle__tooltip-contents">
        <slot name="tooltip" />
      </div>
    </button>
  {/if}
  <slot name="hint"></slot>
</div>

<style lang="scss">
  .toggle {
    position: relative;
    display: flex;
    column-gap: 4px;
    align-items: center;
    &__wrapper {
      user-select: none;
      display: inline-flex;
      cursor: pointer;
      width: 32px;
      height: 16px;
      border-radius: 8px;
      border: 1px solid #D1D5DB;
      background-color: #D1D5DB;
      &:after {
        content: '';
        display: block;
        width: 14px;
        height: 14px;
        border-radius: 7px;
        background-color: white;
      }
      &:has(>:checked)  {
        background-color: red;
        border-color: red;
        justify-content: flex-end;
      }
    }
    &__input {
      appearance: none;
      position: absolute;
    }
    &__tooltip {
      &:before {
        content: '?';
        user-select: none;
        width: 14px;
        height: 14px;
        color: white;
        display: flex;
        background-color: #A8A29F;
        border-radius: 8px;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        cursor: pointer;
        transition: opacity .3s ease-in-out;
        &:hover {
          opacity: .7;
        }
      }
      &-contents {
        display: none;
      }
    }
  }
</style>