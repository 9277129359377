<div class="spinner"></div>

<style>
  .spinner {
    display: block;
    height: 16px;
    width: 16px;
    border: 2px solid #D1D5DB;
    animation: spin 1s linear infinite;
    border-radius: 8px;
    border-top-color: #EF3124;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>