import { get } from 'svelte/store'

import * as Store from './store.module'

export const validate = (input, rules) => {
  const handleBlur = () => validateElement(input, rules)
  const handleInput = () => !elementIsValid(input) ? validateElement(input, rules) : null
  input.addEventListener('blur', handleBlur)
  input.addEventListener('input', handleInput)
  return {
    destroy() {
      input.removeEventListener('blur', handleBlur)
      input.removeEventListener('input', handleInput)
    }
  }
}

const elementIsValid = (element) => element.validity.valid
const makeElementInvalid = (element, elementInvalidity) => element.setCustomValidity(elementInvalidity)
const makeElementValid = (element) => element.setCustomValidity('')

export const validateElement = (element, rules) => {
  const validationFunctions = rules
  for (const functionName of validationFunctions) {
    const elementInvalidityMessage = getInvalidityMessageUnless[functionName](element)
    if (elementInvalidityMessage) {
      makeElementInvalid(element, elementInvalidityMessage)
      return addErrorMessageToElement(element)
    }
  }
  makeElementValid(element)
  removeErrorMessageFromElement(element)
}

const isUpperCase = (string) => string.toUpperCase() === string

const getInvalidityMessageUnless = {
  isNotEmpty: (element) => {
    const elementWrapper = element.parentNode.parentNode
    const label = elementWrapper.querySelector('.input__label')
    const name = label.innerText
    return element.value ? null : `Введите ${isUpperCase(name) ? name : name.toLowerCase()}`
  },
  isValidEmail: (element) => {
    const inputEmail = element.value
    const validEmailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    return inputEmail.match(validEmailRegex) ? null : `Неверный email`
  },
  isValidInspectionCode: (element) => {
    const inspections = get(Store.inspections)
    const inspectionCodes = inspections.map(inspection => inspection.code)
    const inputInspectionCode = Number(element.value)
    return inspectionCodes.includes(inputInspectionCode) ? null : `Неверный код ИМНС`
  },
  isValidActivityCode: (element) => {
    const activities = get(Store.activities)
    const activityCodes = activities.map(activity => activity.code)
    const inputActivityCode = element.value
    return activityCodes.includes(inputActivityCode) ? null : `Неверный код ОКЭД`
  },
  isUserNumber: (element) => {
    const inputNumber = element.value
    if (!inputNumber) return `Введите УНП`
    const validNumberRegex = /[0-9]{9}/
    return inputNumber.match(validNumberRegex) ? null : `Неверный УНП`
  }
}

const addErrorMessageToElement = (element) => {
  const errorText = element.validationMessage
  const elementWrapper = element.parentNode.parentNode
  const errorContainer = elementWrapper.querySelector('.input__error')
  errorContainer.innerText = errorText
}

const removeErrorMessageFromElement = (element) => {
  const elementWrapper = element.parentNode.parentNode
  const errorContainer = elementWrapper.querySelector('.input__error')
  errorContainer.innerText = ''
}