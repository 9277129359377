import * as API from './api.module'
import * as Alfa from './alfa.module'
import * as BSB from './bsb.module'
import * as Dabrabyt from './dabrabyt.module'
import * as Prior from './prior.module'
import BigNumber from '../modules/bignumber.module'
import { sortByDate } from '../modules/utilities.module'

export const getTransactions = async (userNumber, bank, accounts) => {
  const [transactionsFromBank, transactionSelections] = await Promise.all([getTransactionsFromBank(bank, accounts), API.getSelectedTransactions(userNumber)])
  const transactionsWithNonOperatingIncome = addNonOperatingIncomeToTransactions(transactionsFromBank)
  const filteredTransactions = filterTransactions(transactionsWithNonOperatingIncome)
  const transactionsWithSetVisibility = setTransactionVisibility(filteredTransactions)
  const transactionsWithSetTaxability = setTransactionSelection(transactionsWithSetVisibility)
  const selectedTransactions = transactionsWithSetTaxability.map(applyTransactionSelections(transactionSelections))
  return selectedTransactions
}

export const saveSelectedTransactions = (userNumber, transactions) => {
  const selectedTransactions = transactions.filter(({ modified }) => modified)
  if (!selectedTransactions.length) return
  const transactionDataToBeSaved = selectedTransactions.map(({ hash, selected }) => ({ hash, selected }))
  return API.saveSelectedTransactions(userNumber, transactionDataToBeSaved)
}

const applyTransactionSelections = (selections) => (transaction) => {
  const modification = selections.find(modification => modification.hash === transaction.hash) || selections.find(modification => modification.hash === transaction.oldHash)
  if (modification) transaction.selected = modification.selected
  return transaction
}

const getTransactionsFromBank = async (bank, accounts) => {
  if (bank === 'alfa') return Alfa.getTransactionsFromAccounts(accounts)
  if (bank === 'bsb') return BSB.getTransactionsFromAccounts(accounts)
  if (bank === 'dabrabyt') return Dabrabyt.getTransactionsFromAccounts(accounts)
  if (bank === 'prior') return Prior.getTransactionsFromAccounts(accounts)
}

const addNonOperatingIncomeToTransactions = (transactions) => {
  const outgoingTransactions = transactions.filter(transaction => (
    transaction.kind == 'outgoing' &&
    transaction.currency.ISO !== 'BYN'
  ))
  for (const outgoingTransaction of outgoingTransactions) {
    const outgoingTransactionDocumentID = outgoingTransaction.document?.id
    const incomingTransactionWithEqualDocumentID = transactions.find(transaction => (
      transaction.kind == 'incoming' &&
      transaction.currency.ISO == 'BYN' &&
      transaction.document && transaction.document.id === outgoingTransactionDocumentID
    ))
    const incomingTransactionWithEqualDocumentIDDoesNotExist = !Boolean(incomingTransactionWithEqualDocumentID)
    if (incomingTransactionWithEqualDocumentIDDoesNotExist) continue

    const transactionAmountAtPurchaseRate = incomingTransactionWithEqualDocumentID.amount.original
    const transactionAmountAtNationalBankRate = outgoingTransaction.amount.equivalent

    const nationalBankRateIsHigherThanPurchaseRate = transactionAmountAtNationalBankRate > transactionAmountAtPurchaseRate
    if (nationalBankRateIsHigherThanPurchaseRate) continue
    const differenceInAmountsAtPurchaseAndNationalBankRates = parseFloat(BigNumber((transactionAmountAtPurchaseRate * 100 - transactionAmountAtNationalBankRate * 100) / 100).toFormat(2).replace(',', '.'))
    const nonOperatingIncomeTransaction = {
      ...incomingTransactionWithEqualDocumentID,
      purpose: `Внереализационный доход: ${incomingTransactionWithEqualDocumentID.purpose}`,
      amount: {
        original: differenceInAmountsAtPurchaseAndNationalBankRates,
        equivalent: differenceInAmountsAtPurchaseAndNationalBankRates
      }
    }
    transactions.push(nonOperatingIncomeTransaction)
  }
  return transactions.sort(sortByDate)
}


const filterTransactions = (transactions) => {
  const filteredTransactions = transactions.filter(transaction => (
    transactionIsNotRevaluation(transaction) &&
    transactionIsNotTransfer(transaction, transactions)
  ))
  return filteredTransactions
}

const isEntrepreneurSocialInsurancePayment = ({ code, kind }) => {
  if (kind === 'incoming') return false
  const bankESIPCodes = [3512, "03512"]
  return bankESIPCodes.includes(code)
}

const setTransactionSelection = (transactions) => {
  for (let transaction of transactions) {
    transaction.selected = false
    if (!transaction.visible) continue
    if (isEntrepreneurSocialInsurancePayment(transaction)) {
      transaction.selected = true
      continue
    }
    if (transaction.kind === 'outgoing') continue
    if (transaction.purpose.toLowerCase().includes('страхов')) continue
    if (transaction.purpose.toLowerCase().includes('займ')) continue
    if (transaction.purpose.toLowerCase().includes('безвозмездн')) continue
    if (transaction.purpose.toLowerCase().includes('возврат')) continue
    if (transaction.purpose.toLowerCase().includes('уставн')) continue
    if (transaction.purpose.toLowerCase().includes('vozvrat')) continue
    if (transaction.purpose.toLowerCase().includes('переоценка')) continue
    if (transaction.purpose.toLowerCase().includes('пополнение')) continue
    if (transaction.purpose.toLowerCase().includes('перевод в рамках одного лица')) continue
    transaction.selected = true
  }
  return transactions
}

const setTransactionVisibility = (transactions) => {
  for (let transaction of transactions) {
    transaction.visible = false
    if (transaction.purpose.toLowerCase().includes('перевод с продажей валюты')) continue
    if (transaction.purpose.toLowerCase().includes('продажа валюты')) continue
    if (transaction.purpose.toLowerCase().includes('продажа иностранной валюты')) continue
    if (transaction.purpose.toLowerCase().includes('покупка валюты')) continue
    if (transaction.purpose.toLowerCase().includes('рублёвый эквивалент за продажу валюты')) continue
    if (['01201'].includes(transaction.code) && transaction.kind === 'outgoing') continue
    transaction.visible = true
  }
  return transactions
}

const transactionIsNotRevaluation = (transaction) => !['переоценка вход', 'переоценка по счету', 'округление баланса'].some(string => transaction.purpose?.toLowerCase().includes(string))
const transactionIsNotTransfer = (transaction, transactions) => {
  if (!transaction.document) return true
  const counterpartTransactionExists = transactions.find(counterpartTransaction =>
    counterpartTransaction.kind !== transaction.kind &&
    counterpartTransaction.document?.id === transaction.document.id &&
    !transaction.purpose.startsWith("Внереализационный доход")
  )
  const transactionIsNotTransfer = !counterpartTransactionExists
  return transactionIsNotTransfer
}