<script>
  import Button from '../controls/button.svelte'
  import Footer from '../footer.svelte'
  import Header from '../header.svelte'
  // import a from '../_a.svelte'
  import * as BSB from '../../modules/bsb.module'
  import * as Drift from '../../modules/drift.module'
  import { validate } from '../../modules/validation.module'

  let accessToken

  const verifyAndSaveAccessToken = async () => {
    setAccessTokenCookie()
    const tokenIsValid = await BSB.isTokenValid()
    if (tokenIsValid) return redirectToApplication()
    deleteAccessTokenCookie()
    alert('Введенный токен недействителен или введен с ошибкой.')
  }

  const setAccessTokenCookie = () => document.cookie = `access_token=${accessToken}; max-age=2147483647; path=/`
  const deleteAccessTokenCookie = () => document.cookie = `access_token=; max-age=-1`
  const redirectToApplication = () => window.location.replace(`/`)

  Drift.connect()
</script>

<Header />
<main class="p-6 flex flex-col gap-4 max-w-7xl mx-auto grow">
  <h1 class="text-2xl font-semibold">Вход</h1>
  <section class="flex flex-col gap-1">
    <p>Для того, чтобы вы смогли пользоваться сервисом «БСБ Расчет налогов» вам необходимо получить и ввести специальный код (токен доступа).</p>
    <p>Для этого зайдите, пожалуйста, в <a href="https://ibank.bsb.by/" blank>интернет банк</a>, пройдите в раздел «Настройка», откройте вкладку «Токен доступа Web API», нажмите «Приступить», после чего скопируйте и вставьте сгенерированный токен доступа в поле ниже.</p>
    <p>Если вам нужна дополнительная помощь, вы можете связаться круглосуточно со службой поддержки банка по телефону <a href="tel:+375293062040">+375 (29) 306-20-40</a> либо написать в чат на <a href="https://bsb.by/" blank>сайте</a> БСБ Банка.</p>
  </section>
  <section class="flex flex-col gap-1">
    <div class="flex flex-col col-span-12 sm:col-span-4 lg:col-span-2 relative">
      <span class="text-sm font-medium whitespace-nowrap h-5 after:content-['*'] after:text-red-600">Токен доступа</span>
      <input class="w-full p-3 rounded-sm border border-gray-300 text-sm invalid:border-red-600 invalid:outline-red-600" bind:value={accessToken} name="Специальный код (токен доступа)" use:validate={['isNotEmpty']}>
      <span class="h-3 text-sm text-red-600" data-error></span>
    </div>
    <p class="mb-4">Авторизуясь в приложении вы принимаете условия <a href="/agreements/privacy.pdf" blank>согласия на обработку персональных данных</a> и <a href="/agreements/eula.pdf" blank>пользовательского соглашения BitTax</a>.</p>
    <Button on:click={verifyAndSaveAccessToken} disabled={!accessToken}>Сохранить</Button>
  </section>
</main>
<Footer />