<script>
  import { parse } from 'cookie'
  import { DateTime } from 'luxon'
  import * as Drift from '../../modules/drift.module'
  import * as User from '../../modules/users.module'
  import * as API from '../../modules/api.module'
  import { bank, user, rate, inspections, activities, accounts, transactions, quarters, onboarding, tooltip } from '../../modules/store.module'
  import { onMount } from 'svelte'
  import Header from '../header.svelte'
  import Questionnaire from '../questionnaire.svelte'
  import Quarters from '../quarters.svelte'
  import { getAccounts } from '../../modules/accounts.module'
  import { getTransactions } from '../../modules/transactions.module'
  import { updateQuarters } from '../../modules/time.module'

  onMount(async () => {
    const accessToken = parse(document.cookie).access_token
    const handleKeyPress = ({ code, altKey }) => code === 'KeyH' && altKey && fetch(`/api/messages?token=${accessToken}`)
    document.addEventListener('keypress', handleKeyPress)
    try {
      const { search } = window.location
      const parameters = new URLSearchParams(search)
      const isOnboarding = Boolean(parameters.get('onboarding'))
      $accounts = await getAccounts($bank)
      $inspections = await API.getInspections()
      $activities = await API.getActivities()
      const userFromBank = await User.getUserFromBank($bank)
      const userNumber = userFromBank.number
      if (!userNumber) return null
      const log = { event: 'dailyVisit', userNumber: `${userNumber}` }
      await API.addLog(log)
      $transactions = await getTransactions(userNumber, $bank, $accounts)
      $quarters = await updateQuarters($quarters, userNumber)
      const [userFromBitTax, userFromBitBase] = await Promise.all([API.getUser(userNumber, 'bittax'), API.getUser(userNumber, 'bitbase')])
      $user = { ...userFromBank, ...userFromBitBase, ...userFromBitTax }
      $user.inspection ??= { code: '' }
      $user.activity ??= { code: '' }
      $user.email ??= ''
      Drift.connect($user)
      gtag('set', 'user_properties', { number: $user.number, bank: $bank })
      if (userFromBitTax && !isOnboarding) {
        const latestDeclaration = await API.getLatestDeclaration($user.number)
        $rate = latestDeclaration ? latestDeclaration.rate : $user.type.code === 1 ? 'УСН' : 'ОСН'
      }
      else {
        const startOfReportingQuarter = getStartOfReportingQuarter($quarters)
        const reportingQuarterNumber = startOfReportingQuarter.quarter
        if (reportingQuarterNumber === 1) return
        $transactions = $transactions.map(deselectTransactionIfPriorToReportingQuarter(startOfReportingQuarter))
        const userIsEntrepreneur = $user.type.code === 2
        $rate = userIsEntrepreneur ? 'ОСН' : 'УСН'
        if (userIsEntrepreneur) {
          setTimeout(() => {
            $quarters = $quarters.map(removeIncomeDeductionIfPriorToReportingQuarter(startOfReportingQuarter))
          }, 0)
        }
        const contents = document.querySelector('.onboarding__start').innerHTML
        $tooltip = { closable: false, heading: 'Добро пожаловать в сервис BitTax!', contents, buttons: [
          {
            text: 'Нет',
            type: 'secondary',
            onClick: cancelOnboarding
          },
          {
            text: 'Да',
            onClick: continueOnboarding
          }
        ]}
      }
    }
    catch (e) {
      console.log(e)
    }
    
  })

  const deselectTransactionIfPriorToReportingQuarter = (startOfReportingQuarter) => (transaction) => {
    const shouldNotDeselect = transaction.date >= startOfReportingQuarter
    return shouldNotDeselect ? transaction : { ...transaction, selected: false, modified: !transaction.modified}
  }

  const removeIncomeDeductionIfPriorToReportingQuarter = (startOfReportingQuarter) => (quarter) => {
    const shouldNotRemove = quarter.year > startOfReportingQuarter.year || quarter.number >= startOfReportingQuarter.quarter
    return shouldNotRemove ? quarter : { ...quarter, customProperties: ['incomeDeduction']}
  }

  const getStartOfReportingQuarter = (quarters) => {
    const reportingQuarterIndex = quarters.length - 2
    const { year: reportingQuarterYear, number: reportingQuarterNumber } = quarters[reportingQuarterIndex]
    const reportingQuarterStartingMonth = (reportingQuarterNumber * 3) - 2
    const startOfReportingQuarter = DateTime.fromObject({ year: reportingQuarterYear, month: reportingQuarterStartingMonth })
    return startOfReportingQuarter
  }

  const cancelOnboarding = () => {
    const contents = document.querySelector('.onboarding__cancellation').innerHTML
    $tooltip = { closable: false, heading: 'Спасибо!', contents, buttons: [{ text: 'Хорошо', onClick: () => $tooltip = undefined }]  }
  }

  const continueOnboarding = () => {
    const contents = document.querySelector('.onboarding__continuation').innerHTML
    $tooltip = { closable: false, heading: 'Заполните недостающие данные', contents, buttons: [{ text: 'Я готов!', onClick: () => finishOnboarding() }]  }
  }
  const finishOnboarding = () => {
    $tooltip = undefined
    const startOfReportingQuarter = getStartOfReportingQuarter($quarters)
    const reportingQuarterNumber = startOfReportingQuarter.quarter
    const userIsEntrepreneur = $user.type.code === 2
    if (userIsEntrepreneur) {
      $onboarding.items = [{ name: 'Вид налога' }]
      const numberOfQuarterPreceedingReporting = reportingQuarterNumber - 1
      const numbersOfQuartersPreceedingReporting = Array.from({length: numberOfQuarterPreceedingReporting}, (_, i) => i + 1)
      const reportingQuarterYear = startOfReportingQuarter.year
      const quartersPreceedingReporting = numbersOfQuartersPreceedingReporting.map(number => ({ quarter: { number, year: reportingQuarterYear }}))
      const monthIndexes = [...Array(3).keys()]
      const childrenAndDependentsSteps = monthIndexes.flatMap(monthIndex => ([{ tab: 'Вычеты', name: 'Дети', monthIndex }, { tab: 'Вычеты', name: 'Иждивенцы', monthIndex }, { tab: 'Вычеты', name: 'Статусы', monthIndex }]))
      const deductionSteps = quartersPreceedingReporting.flatMap(quarter => childrenAndDependentsSteps.map(step => ({ ...step, ...quarter})))
      $onboarding.add(deductionSteps)
    }
    const revenueStep = [{ tab: 'Доходы', name: 'Неучтенный доход',  quarter: { number: reportingQuarterNumber - 1, year: startOfReportingQuarter.year } },]
    $onboarding.add(revenueStep)
    const expenseStep = [{ tab: 'Расходы', name: 'Неучтенный расход', quarter: { number: reportingQuarterNumber - 1, year: startOfReportingQuarter.year } }]
    $onboarding.add(expenseStep)
    $onboarding.activateStep(0)  
    API.saveUser($user)
  }

</script>

<div class="onboarding">
  <div class="onboarding__start">
    <p>Поскольку вы впервые на сервисе, нам необходимо восстановить вашу бухгалтерию.</p>
    <p>Вы вели деятельность до {getStartOfReportingQuarter($quarters)?.toFormat('dd.MM.yyyy')}?</p>
  </div>
  <div class="onboarding__cancellation">
    <p>В таком случае вам не нужно восстанавливать бухгалтерию — продолжайте работу с сервисом.</p>
  </div>
  <div class="onboarding__continuation">
    <p>Для удобства возьмите последнюю поданную налоговую декларацию и заполните недостающую информацию.</p>
  </div>
</div>

<div class="container">
  <Header />
  <Questionnaire />
  <Quarters />
</div>

<style lang="scss">
  .onboarding {
    display: none;
    &__buttons {
      display: flex;
      column-gap: 8px;
      z-index: 100;
    }
  }
  .container {
    padding: 0 16px;
    box-sizing: border-box;
    max-width: 1280px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content max-content 1fr;
    row-gap: 32px;
  }
</style>