<script>
  import Header from '../header.svelte'
  import Footer from '../footer.svelte'
</script>

<Header />
<main class="app">
  <h1>404</h1>
  <p class="text">Запрашиваемая вами страница не найдена.</p>
  <a class="button button--width_auto" href="/">Вернуться на главную</a>
</main>
<Footer />

<style lang="scss">
  h1 {
    font-size: 24px;
    margin: 0;
  }
  .app {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
    margin: 0 auto 48px;
    padding: 32px 32px;
    width: 1280px;
    @media (max-width: 1280px) {
      width: 100%;
    }
  }
  .text {
    font-size: 16px;
  }
</style>