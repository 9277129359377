<script>
  import { onMount } from 'svelte'
  import Header from '../header.svelte'
  import Footer from '../footer.svelte'
  import { bank } from '../../modules/store.module'

  let requirementsContainer
  onMount(async () => {
    const request = await fetch('/api/requirements')
    const requirements = await request.text()
    requirementsContainer.insertAdjacentHTML('beforeend', requirements)
  })

</script>

<Header />

<main class="grow max-w-7xl w-full self-center p-6 instructions {$bank}" bind:this={requirementsContainer}>
  <h1>Технические требования</h1>
</main>
<Footer />