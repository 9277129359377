<script>
  import { quarters, transactions, tabs } from '../modules/store.module'
  import Quarter from './quarter.svelte'
  import Button from './controls/button.svelte'
  import Tabs from './tabs.svelte'

  $: activeTabIndex = $tabs.items.findIndex(isActiveTab)
  const isActiveTab = ({ active }) => active
  const activateTab = (direction) => {
    if (direction === 'previous') $tabs.previous()
    if (direction === 'next') $tabs.next()
    const top = document.querySelector('.quarters').getBoundingClientRect().top + window.scrollY
    window.scrollTo({ top, behavior: 'instant' })
  }
</script>

<section class="quarters">
  <Tabs bind:quarters={$quarters}/>
  {#each $quarters as quarter}
    <Quarter bind:quarter quarters={$quarters} transactions={$transactions}/>
  {/each}
  <div class="quarters__buttons">
    <Button disabled={activeTabIndex === 0} on:click={() => activateTab('previous')} type={'secondary'}>Назад</Button>
    <Button disabled={activeTabIndex === $tabs.items.length - 1} on:click={() => activateTab('next')} type={'primary'}>Далее</Button>
  </div>
</section>

<style lang="scss">
  .quarters {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content 1fr;
    &__buttons {
      display: none;
      column-gap: 8px;
      padding: 8px 0;
      border-top: 1px solid #E7E5E4;
      position: sticky;
      bottom: 0;
      background-color: white;
      @media (max-width: 768px) {
        display: flex;
      }
    }
  }
</style>