import BigNumber from 'bignumber.js'

const FORMAT = {
  prefix: '',
  decimalSeparator: ',',
  groupSeparator: ' ',
  groupSize: 3,
  secondaryGroupSize: 0,
  fractionGroupSeparator: ' ',
  fractionGroupSize: 0,
  suffix: ''
}

BigNumber.config({ FORMAT })
BigNumber.prototype.toZeroIfNegative = function () { return this.isNegative() ? BigNumber(0) : this }

class Num extends BigNumber {
  constructor(number, base) {
    if (typeof number === 'string') number = number.replaceAll(' ', '').replaceAll(',', '.')
    super(number, base)
  }
}
var _old = Num
Num = function (...args) { return new _old(...args) }

export default Num