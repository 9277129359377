<script>
  import { bank } from '../../modules/store.module'
  const { pathname } = window.location
  const currentLocationIsHomepage = pathname === '/'
</script>

<a class="bittax-logo" href='/' class:bittax-logo--disabled="{currentLocationIsHomepage}">
  <svg class="logomark inline " width="30" height="48" viewBox="0 0 30 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 25H30C30 16.7157 23.2843 10 15 10C6.71573 10 0 16.7157 0 25Z" fill="url(#paint0_linear)"></path>
    <path d="M0 0C8.28427 0 15 6.71573 15 15V25H0V0Z" fill="url(#paint1_linear)"></path>
    <path class="fill_{$bank}" d="M0 25H30C30 33.2843 23.2843 40 15 40C6.71573 40 0 33.2843 0 25Z"></path>
    <defs>
      <linearGradient id="paint0_linear" x1="15" y1="25" x2="0" y2="10" gradientUnits="userSpaceOnUse">
        <stop class="stop_{$bank}"></stop>
        <stop class="stop_{$bank}" offset="1" stop-opacity="0.4"></stop>
      </linearGradient>
      <linearGradient id="paint1_linear" x1="-15" y1="10.7143" x2="-0.775632" y2="25.7345" gradientUnits="userSpaceOnUse">
        <stop class="stop_{$bank}" stop-opacity="0.4"></stop>
        <stop class="stop_{$bank}" offset="1"></stop>
      </linearGradient>
    </defs>
  </svg>
  <svg class="logotype inline " width="98" height="48" viewBox="0 0 98 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.4333 16.2333C25.7222 16.2333 27.6778 17.0778 29.3 18.7667C30.9222 20.4556 31.7333 22.5333 31.7333 25C31.7333 27.4444 30.9222 29.5222 29.3 31.2333C27.6778 32.9222 25.7222 33.7667 23.4333 33.7667C20.9 33.7667 18.9556 32.8222 17.6 30.9333V33.3333H14V10H17.6V19.0333C18.9556 17.1667 20.9 16.2333 23.4333 16.2333ZM19.1 28.8333C20.1 29.8333 21.3556 30.3333 22.8667 30.3333C24.3778 30.3333 25.6333 29.8333 26.6333 28.8333C27.6333 27.8111 28.1333 26.5333 28.1333 25C28.1333 23.4667 27.6333 22.2 26.6333 21.2C25.6333 20.1778 24.3778 19.6667 22.8667 19.6667C21.3556 19.6667 20.1 20.1778 19.1 21.2C18.1 22.2 17.6 23.4667 17.6 25C17.6 26.5333 18.1 27.8111 19.1 28.8333Z" fill="#060A0E"></path>
    <path d="M33.6333 33.3333V16.6667H37.2333V33.3333H33.6333Z" fill="#060A0E"></path>
    <path d="M50.076 20.1333H45.9427V28.1333C45.9427 28.8222 46.0983 29.3222 46.4094 29.6333C46.7205 29.9222 47.176 30.0889 47.776 30.1333C48.3983 30.1556 49.1649 30.1444 50.076 30.1V33.3333C47.3205 33.6667 45.3427 33.4333 44.1427 32.6333C42.9427 31.8111 42.3427 30.3111 42.3427 28.1333V20.1333H39.276V16.6667H42.3427V13.0667L45.9427 12V16.6667H50.076V20.1333Z" fill="#060A0E"></path>
    <path d="M60.8599 20.1333H56.7266V28.1333C56.7266 28.8222 56.8821 29.3222 57.1932 29.6333C57.5043 29.9222 57.9599 30.0889 58.5599 30.1333C59.1821 30.1556 59.9488 30.1444 60.8599 30.1V33.3333C58.1043 33.6667 56.1266 33.4333 54.9266 32.6333C53.7266 31.8111 53.1266 30.3111 53.1266 28.1333V20.1333H50.0599V16.6667H53.1266V13.0667L56.7266 12V16.6667H60.8599V20.1333Z" fill="#060A0E"></path>
    <path d="M75.9667 16.6667H79.5667V33.3333H75.9667V30.9333C74.6111 32.8222 72.6667 33.7667 70.1333 33.7667C67.8444 33.7667 65.8889 32.9222 64.2667 31.2333C62.6444 29.5222 61.8333 27.4444 61.8333 25C61.8333 22.5333 62.6444 20.4556 64.2667 18.7667C65.8889 17.0778 67.8444 16.2333 70.1333 16.2333C72.6667 16.2333 74.6111 17.1667 75.9667 19.0333V16.6667ZM66.9333 28.8333C67.9333 29.8333 69.1889 30.3333 70.7 30.3333C72.2111 30.3333 73.4667 29.8333 74.4667 28.8333C75.4667 27.8111 75.9667 26.5333 75.9667 25C75.9667 23.4667 75.4667 22.2 74.4667 21.2C73.4667 20.1778 72.2111 19.6667 70.7 19.6667C69.1889 19.6667 67.9333 20.1778 66.9333 21.2C65.9333 22.2 65.4333 23.4667 65.4333 25C65.4333 26.5333 65.9333 27.8111 66.9333 28.8333Z" fill="#060A0E"></path>
    <path d="M97.6628 33.3333H93.4961L89.3628 27.6333L85.1961 33.3333H80.9961L87.2628 24.7667L81.3294 16.6667H85.4961L89.3294 21.9333L93.1628 16.6667H97.3628L91.4294 24.8L97.6628 33.3333Z" fill="#060A0E"></path>
  </svg>
</a>

<style lang="scss">
  .bittax-logo {
    text-decoration: none;
    &--disabled {
      cursor: default;
      pointer-events: none;
    }
  }
  .stop {
    &_bittax {
      stop-color: $alfa
    }
    &_alfa {
      stop-color: $alfa
    }
    &_bsb {
      stop-color: $bsb
    }
    &_dabrabyt {
      stop-color: $dabrabyt
    }
    &_prior {
      stop-color: $prior
    }
  }
  .fill {
    &_bittax {
      fill: $alfa
    }
    &_alfa {
      fill: $alfa
    }
    &_bsb {
      fill: $bsb
    }
    &_dabrabyt {
      fill: $dabrabyt
    }
    &_prior {
      fill: $prior
    }
  }
  .logotype {
    @media (max-width: 768px) {
      display: none;
    }
  }
</style>