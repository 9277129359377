<script>
  import BigNumber from '../modules/bignumber.module'
  import { activeTab, rate } from '../modules/store.module'
  import Revenue from './revenue.svelte'
  import Expenses from './expenses.svelte'
  import Deductions from './deductions.svelte'
  import Workers from './workers.svelte'
  import Total from './total.svelte'

  export let quarter
  export let quarters
  export let transactions

  const subtract = (a, b) => BigNumber(a).minus(BigNumber(b))
  const isInQuarter = ({ year, number }) => ({ date }) => date.year === year && date.quarter === number
  const isOfKind = (kind) => (transaction) => transaction.kind === kind

  $: transactionsInQuarter = transactions?.filter(isInQuarter(quarter))
  $: incomingTransactionsInQuarter = transactionsInQuarter?.filter(isOfKind('incoming'))
  $: outgoingTransactionsInQuarter = transactionsInQuarter?.filter(isOfKind('outgoing'))
  $: quarter.profit = subtract(quarter.revenue, quarter.expenses).toFormat(2)
  $: quarter.taxBase = subtract(quarter.profit, quarter.totalDeduction).toZeroIfNegative().toFormat(2)
</script>

<div class="quarters__tab" hidden={$activeTab !== 'Доходы' || !quarter.selected}>
  <Revenue bind:quarter transactions={incomingTransactionsInQuarter}/>
</div>
<div class="quarters__tab" hidden={$activeTab !== 'Расходы' || !quarter.selected}>
  <Expenses bind:quarter transactions={outgoingTransactionsInQuarter}/>
</div>
<div class="quarters__tab" hidden={$activeTab !== 'Вычеты' || !quarter.selected}>
  <Deductions 
    bind:incomeDeduction={quarter.incomeDeduction}
    bind:inferiorDeduction={quarter.inferiorDeduction}
    bind:specialStatusDeduction={quarter.specialStatusDeduction}
    bind:socialDeduction={quarter.socialDeduction}
    bind:standardDeduction={quarter.standardDeduction}
    bind:propertyDeduction={quarter.propertyDeduction}
    bind:childrenDeduction={quarter.childrenDeduction}
    bind:dependentsDeduction={quarter.dependentsDeduction}
    bind:totalDeduction={quarter.totalDeduction}
    bind:isEmployed={quarter.isEmployed}
    bind:doesExist={quarter.doesExist}
    bind:isSpecialParent={quarter.isSpecialParent}
    bind:isSpecialStatus={quarter.isSpecialStatus}
    bind:children={quarter.children}
    bind:dependents={quarter.dependents}
    bind:inferiors={quarter.inferiors}
    bind:profit={quarter.profit}
    bind:customProperties={quarter.customProperties}
    bind:number={quarter.number}
    bind:year={quarter.year}
  />
</div>
<div class="quarters__tab" hidden={$activeTab !== 'Работники' || !quarter.selected}>
  <Workers 
    bind:number={quarter.number}
    bind:incomeTax={quarter.incomeTax}
    bind:wageFund={quarter.wageFund}
    bind:wageRate={quarter.wageRate}
    bind:totalIncomeTax={quarter.totalIncomeTax}
    bind:totalWageFund={quarter.totalWageFund}
    bind:totalWageRate={quarter.totalWageRate}
    bind:profit={quarter.profit}
    bind:customProperties={quarter.customProperties}
    transactions={outgoingTransactionsInQuarter}/>
</div>
<div class="quarters__tab" hidden={$activeTab !== 'Итого' || !quarter.selected}>
  <Total 
  bind:quarters 
  bind:customProperties={quarter.customProperties}
  {transactions}/>
</div>

<style>
.quarters__tab:not([hidden]) {
  display: grid;
  grid-template-rows: 1fr;
}
</style>