import { DateTime } from 'luxon'
import { get } from 'svelte/store'

import { parse } from 'cookie'

import BigNumber from './bignumber.module'
import * as Fetch from './fetch.module'
import * as Store from './store.module'
import * as Time from './time.module'
import * as Utilities from './utilities.module'

const getAuthHeader = () => ({ 'Authorization': `Bearer ${parse(document.cookie).access_token}` })

export const getAccounts = async () => {
  const { accounts: unformattedAccounts } = await Fetch.get('https://developerhub.alfabank.by:8273/partner/1.2.0/accounts/', getAuthHeader())
  const formattedAccounts = formatAccounts(unformattedAccounts)
  return formattedAccounts
}

const formatAccounts = (unformattedAccounts) => unformattedAccounts.map(({ currIso, currCode, amount, number, type }) => ({ number, type, amount, currency: { code: currCode, ISO: currIso } }))

export const getUserProperties = async () => {
  const unformattedUser = await Fetch.get('https://developerhub.alfabank.by:8273/partner/1.0.0/user/profile', getAuthHeader())
  return formatUser(unformattedUser)
}

const startsWith = (string) => (beginning) => string.toLowerCase().trim().startsWith(beginning)

const formatUser = (user) => {
  const name = `${user.lastName} ${user.firstName} ${user.patronymic}`.trim()
  const entityIndicators = ['ооо', 'чуп', 'чп', 'частное', 'общество']
  const userIsEntity = entityIndicators.some(startsWith(user.companyName))
  const formattedUser = {
    bank: 'alfa',
    number: user.companyUnp,
    address: user.companyAddress,
    name,
    email: user.email,
    phone: user.mobilePhone,
    type: {
      code: userIsEntity ? 1 : 2,
      name: userIsEntity ? 'Юридическое лицо' : 'Индивидуальный предприниматель',
    },
    title: userIsEntity ? user.companyName : `ИП ${name}`,
    activity: {
      code: '',
    },
    inspection: {
      code: '',
    },
  }
  return formattedUser
}

export const getTransactionsFromAccounts = async (accounts) => {
  const accountNumbers = accounts.map(account => account.number)
  const transactions = await getTransactionsByAccountNumbers(accountNumbers)
  const acquiringFees = await getAcquiringFees(accounts)
  return [...transactions, ...acquiringFees]
}

const getAcquiringFees = async (accounts) => {
  const accountsInBYN = accounts.filter(account => account.currency.ISO === 'BYN')
  const accountNumbers = accountsInBYN.map(account => account.number)
  const quarters = get(Store.quarters)
  const acquiringFeesByPeriodPromises = quarters.map(getAcquiringFeesInPeriod(accountNumbers))
  const acquiringFeesByPeriod = await Promise.all(acquiringFeesByPeriodPromises)
  const acquiringFees = acquiringFeesByPeriod.flat()
  return acquiringFees
}

const getAcquiringFeesInPeriod = (accountNumbers) => async (period) => {
  try {
    const acquiringDetailsInPeriodPromises = accountNumbers.map(getAcquiringDetailsByAccountNumber(period))
    const acquiringDetailsInPeriod = await Promise.all(acquiringDetailsInPeriodPromises)
    const acquiringFeeSumsInPeriod = acquiringDetailsInPeriod.map(({ summary: { feeSum } }) => Math.abs(feeSum))
    const acquiringFeeInPeriod = acquiringFeeSumsInPeriod.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    if (acquiringFeeInPeriod === 0) return []
    const acquiringFeeTransactionsInPeriod = convertAcquiringFeeToTransactions(acquiringFeeInPeriod, period)
    return acquiringFeeTransactionsInPeriod
  }
  catch (e) {
    return []
  }
}

const getAcquiringDetailsByAccountNumber = (period) => (accountNumber) => {
  try {
    const requestParameterData = {
      'account': accountNumber,
      'types[0]': 'CANCEL',
      'types[1]': 'CREDIT',
      'types[2]': 'REFUND',
      'dateRangeProperty': 'CREATE',
      'dateRange.name': 'CUSTOM',
      'dateRange.range.start': Time.getStartOfQuarter(period).toFormat('yyyy-MM-dd'),
      'dateRange.range.end': Time.getEndOfQuarter(period).toFormat('yyyy-MM-dd'),
      'offset': 0,
      'count': 1000,
      'properties[0].property': 'CREATE_TIMESTAMP',
      'properties[0].order': 'DESC'
    }
    const requestParameters = new URLSearchParams(requestParameterData)
    const requestBaseURL = `https://developerhub.alfabank.by:8273/partner/1.19.0/acquiring/statement`
    const requestURL = `${requestBaseURL}?${requestParameters.toString()}`
    return Fetch.get(requestURL, getAuthHeader())
  }
  catch (e) {
    console.log(e)
  }

}

const convertAcquiringFeeToTransactions = (acquiringFee, period) => {
  const date = Time.getEndOfQuarter(period)
  const purpose = `Комиссия за эквайринг в ${period.number} кв. ${period.year} г.`
  const transactionUnixInteger = date.toMillis()
  const acquiringFeeTransaction = {
    date,
    correspondent: { name: 'ЗАО "Альфа-Банк"' },
    currency: { code: 933, ISO: 'BYN' },
    purpose,
    amount: {
      original: acquiringFee,
      equivalent: acquiringFee
    },
    active: false,
    special: false
  }
  const incomingAcquiringFeeTransaction = { ...acquiringFeeTransaction, kind: 'incoming', document: { id: `IF${transactionUnixInteger}` }, hash: Utilities.generateHashFromString(`${transactionUnixInteger}.incoming.${purpose}.${acquiringFee}`) }
  const outgoingAcquiringFeeTransaction = { ...acquiringFeeTransaction, kind: 'outgoing', document: { id: `OF${transactionUnixInteger}` }, hash: Utilities.generateHashFromString(`${transactionUnixInteger}.outgoing.${purpose}.${acquiringFee}`) }
  const acquiringFeeTransactions = [incomingAcquiringFeeTransaction, outgoingAcquiringFeeTransaction]
  return acquiringFeeTransactions
}


const getTransactionsByAccountNumbers = async (accountNumbers) => {
  const requestParameterData = {
    pageNo: 0,
    number: accountNumbers.join(','),
    dateFrom: Time.firstDayOfSameMonthYearAgo.toFormat('dd.MM.yyyy'),
    dateTo: Time.currentDateTimeInBelarus.toFormat('dd.MM.yyyy')
  }
  const requestParameters = new URLSearchParams(requestParameterData)
  const requestBaseURL = `https://developerhub.alfabank.by:8273/partner/1.2.0/accounts/statement`
  const requestURL = `${requestBaseURL}?${requestParameters.toString()}`
  const unformattedTransactions = (await Fetch.get(requestURL, getAuthHeader())).page
  const formattedTransactions = formatTransactions(unformattedTransactions)
  return formattedTransactions
}

const formatTransactions = (unformattedTransactions) => {
  let formattedTransactions = []
  for (const unformattedTransaction of unformattedTransactions) {
    const transactionDateParts = unformattedTransaction.operDate.split('.')
    const transactionISODate = `${transactionDateParts[2]}-${transactionDateParts[1]}-${transactionDateParts[0]}`
    const transactionDate = DateTime.fromISO(transactionISODate, { zone: 'Europe/Minsk' })
    const oldTransactionDate = DateTime.fromISO(unformattedTransaction.acceptDate, { zone: 'Europe/Minsk' })
    const transactionUnixInteger = transactionDate.toUnixInteger()
    const oldTransactionUnixInteger = oldTransactionDate.toUnixInteger()
    const transactionKind = unformattedTransaction.operType == 'C' ? 'incoming' : 'outgoing'
    const formattedTransaction = {
      oldHash: Utilities.generateHashFromString(`${oldTransactionUnixInteger}.${transactionKind}.${unformattedTransaction.purpose}.${unformattedTransaction.amountEq}`),
      hash: Utilities.generateHashFromString(`${transactionUnixInteger}.${transactionKind}.${unformattedTransaction.purpose}.${unformattedTransaction.amountEq}`),
      active: false,
      special: false,
      kind: transactionKind,
      date: transactionDate,
      purpose: unformattedTransaction.purpose,
      account: {
        number: unformattedTransaction.number,
        bank: {
          code: 'ALFABY2X'
        }
      },
      amount: {
        equivalent: unformattedTransaction.amountEq,
        original: unformattedTransaction.amount
      },
      correspondent: {
        name: unformattedTransaction.corrName.replace(/IN[A-Z]{1}[0-9]{9}/, '').trim(),
        number: unformattedTransaction.corrUnp,
        account: {
          number: unformattedTransaction.corrNumber,
          bank: {
            name: unformattedTransaction.corrBank,
            code: unformattedTransaction.corrBic
          }
        }
      },
      currency: {
        code: unformattedTransaction.currCode,
        ISO: unformattedTransaction.currIso
      },
      ...(unformattedTransaction.docId && { document: { id: unformattedTransaction.docId } }),
      ...(unformattedTransaction.budgetCode && { code: unformattedTransaction.budgetCode })
    }
    formattedTransactions.push(formattedTransaction)
  }
  return formattedTransactions.reverse()
}

export const payTax = async (declaration, declarationId) => {
  const paymentOrderId = await createPaymentOrderWithPeriodDataAndGetItsId(declaration)
  const paymentTicketId = await signPaymentOrderByPaymentOrderIdWithDeclarationIdAndGetItsTicketId(paymentOrderId, declarationId)
  redirectToTicketByPaymentTicketId(paymentTicketId)
}


const createPaymentOrderWithPeriodDataAndGetItsId = async (declaration) => {
  const { rate, user, quarter } = declaration
  const { number, year, paymentDate } = quarter
  const allInspections = get(Store.inspections)
  const specifiedInspectionCode = user.inspection.code
  const specifiedInspection = allInspections.find(inspection => inspection.code == specifiedInspectionCode)
  const accounts = get(Store.accounts)
  const selectedAccount = accounts.find(account => account.active) || accounts.find(account => account.currency.ISO === 'BYN')
  const purposeText = rate == 'УСН' ? 'налога при УСН' : 'подоходного налога'
  const budgetCode = rate == 'УСН' ? '01201' : '00102'
  const paymentAmount = declaration.payment.actual
  const expectedAmount = declaration.payment.expected
  const paymentOrderData = {
    document: {
      type: 'budgetPayments',
      date: Time.currentDateTimeInBelarus.toFormat('dd.MM.yyyy'),
      num: '1',
      params: [
        {
          name: 'type',
          value: 'budgetPayments'
        },
        {
          name: 'executionDate',
          value: Time.currentDateTimeInBelarus.toFormat('dd.MM.yyyy')
        },
        {
          name: 'number',
          value: selectedAccount.number
        },
        {
          name: 'corrName',
          value: specifiedInspection.beneficiary.name
        },
        {
          name: 'corrUnp',
          value: specifiedInspection.beneficiary.number
        },
        {
          name: 'corrNumber',
          value: specifiedInspection.beneficiary.account.number
        },
        {
          name: 'corrBic',
          value: specifiedInspection.beneficiary.account.bank.code
        },
        {
          name: 'factReceiverName',
          value: specifiedInspection.receiver.name
        },
        {
          name: 'factReceiverUNP',
          value: specifiedInspection.receiver.number
        },
        {
          name: 'amount',
          value: BigNumber(paymentAmount).toNumber()
        },
        {
          name: 'purpose',
          value: `Уплата ${purposeText} (${expectedAmount} BYN) за ${number} квартал ${year} года по сроку уплаты ${paymentDate}`
        },
        {
          name: 'priority',
          value: '13'
        },
        {
          name: 'budgetCode',
          value: budgetCode
        },
        {
          name: 'isPayment',
          value: 1
        },
        {
          name: 'purposeCode',
          value: 90101
        },
        {
          name: 'categoryPurposeCode',
          value: 'TAXS'
        }
      ]
    }
  }
  const { id: paymentOrderId } = (await Fetch.post('https://developerhub.alfabank.by:8273/partner/1.0.3/documents/', paymentOrderData, getAuthHeader()))
  return paymentOrderId
}

const signPaymentOrderByPaymentOrderIdWithDeclarationIdAndGetItsTicketId = async (paymentOrderId, declarationId) => {
  const { origin } = window.location
  const paymentTicketData = {
    queryIds: [paymentOrderId],
    redirectUrl: `${origin}/declarations/${declarationId}?paid`
  }
  const paymentTicketId = (await Fetch.post('https://developerhub.alfabank.by:8273/partner/1.0.3/documents/signs/initialize', paymentTicketData, getAuthHeader())).ticket
  const paymentTicketHasNotBeenCreated = !Boolean(paymentTicketId)
  if (paymentTicketHasNotBeenCreated) return deletePaymentOrderByPaymentOrderId(paymentOrderId)
  return paymentTicketId
}

const deletePaymentOrderByPaymentOrderId = async (paymentOrderId) => {
  const deletedPaymentOrder = await Fetch.del(`https://developerhub.alfabank.by:8273/partner/1.0.3/documents/${paymentOrderId}`, getAuthHeader())
  return deletedPaymentOrder
}

const redirectToTicketByPaymentTicketId = (paymentTicketId) => {
  const redirectURL = `https://developerhub.alfabank.by:8273/partner/1.0.3/documents/signs/verify?ticket=${paymentTicketId}`
  window.location.replace(redirectURL)
}