import { DateTime } from 'luxon'
import * as API from './api.module.js'

const HOLIDAYS = [
  DateTime.fromISO('2023-04-24'),
  DateTime.fromISO('2023-04-25'),
]

const datesAreEqual = (dateA) => (dateB) => dateA.startOf('day').equals(dateB.startOf('day'))
const dateIsAHoliday = (date) => HOLIDAYS.find(datesAreEqual(date))
const dateIsAWeekend = (date) => [6, 7].includes(date.weekday)

export const getPaymentDateByQuarterNumberAndQuarterYear = (quarterNumber, quarterYear) => {
  const startOfReportingQuarter = DateTime.fromObject({ month: (quarterNumber * 3) - 2, year: quarterYear })
  let paymentDate = startOfReportingQuarter.plus({ quarters: 1, days: 21 })
  while (dateIsAHoliday(paymentDate) || dateIsAWeekend(paymentDate)) paymentDate = paymentDate.plus({ days: 1 })
  return paymentDate
}

export const getSubmissionDateByQuarterNumberAndQuarterYear = (quarterNumber, quarterYear) => {
  const startOfReportingQuarter = DateTime.fromObject({ month: (quarterNumber * 3) - 2, year: quarterYear })
  const allegedSubmissionDate = startOfReportingQuarter.plus({ quarters: 1, days: 19 })
  const allegedSubmissionDateIsOnWeekday = [6, 7].includes(allegedSubmissionDate.weekday)
  const daysToAddToAllegedSubmissionDay = allegedSubmissionDateIsOnWeekday ? 8 - allegedSubmissionDate.weekday : 0
  const submissionDate = allegedSubmissionDate.plus({ days: daysToAddToAllegedSubmissionDay })
  return submissionDate
}

export const currentDateTimeInBelarus = DateTime.now().setZone('Europe/Minsk')
export const yearAgo = currentDateTimeInBelarus.minus({ years: 1 })
export const firstDayOfSameMonthYearAgo = yearAgo.startOf('month')
export const oneQuarterAgo = currentDateTimeInBelarus.minus({ quarters: 1 })
export const startOfCurrentQuarter = currentDateTimeInBelarus.startOf('quarter')
export const endOfCurrentQuarter = currentDateTimeInBelarus.endOf('quarter')
export const startOfPreviousQuarter = oneQuarterAgo.startOf('quarter')
export const endOfPreviousQuarter = oneQuarterAgo.endOf('quarter')
export const reportingQuarter = Number(startOfPreviousQuarter.toFormat('q'))

export const currentQuarter = Number(currentDateTimeInBelarus.toFormat('q'))
export const currentYear = Number(currentDateTimeInBelarus.toFormat('yyyy'))
export const reportingYear = Number(startOfPreviousQuarter.toFormat('yyyy'))

export const twoQuartersAgo = currentDateTimeInBelarus.minus({ quarters: 2 })
export const preceedingQuarter = Number(twoQuartersAgo.toFormat('q'))
export const preceedingYear = Number(twoQuartersAgo.toFormat('yyyy'))

export const currentYearIsReporting = currentYear === reportingYear

const matchesQuarter = (a) => (b) => a.year === b.year && a.number === b.number

const applyCustomQuarter = (customQuarters) => (quarter) => {
  const properties = customQuarters.find(matchesQuarter(quarter))?.properties
  if (!properties) return quarter
  const customPropertyKeys = ['incomeDeduction', 'inferiorDeduction', 'specialStatusDeduction', 'incomeTax', 'wageFund']
  const customProperties = Object.keys(properties).filter(key => customPropertyKeys.includes(key))
  return { ...quarter, ...properties, customProperties }
}

export const generateQuarters = () => {
  let quarters = currentYearIsReporting ? generateThisYearQuarters() : generatePreviousYearQuartersWithCurrentQuarter()
  quarters[quarters.length - 2].selected = true
  quarters = quarters.map(setProperties)
  return quarters
}
export const updateQuarters = async (quarters, userNumber) => {
  const customQuarters = await API.getQuarters(userNumber)
  const updatedQuarters = quarters.map(applyCustomQuarter(customQuarters))
  return updatedQuarters
}

export const generateThisYearQuarters = () => {
  const quarterNumbers = [...Array(currentQuarter).keys()].map(i => i + 1)
  let quarters = quarterNumbers.map(number => ({ number, year: currentYear }))
  return quarters
}

export const generatePreviousYearQuartersWithCurrentQuarter = () => {
  const quarterNumbers = [...Array(4).keys()].map(i => i + 1)
  const previousYearQuarters = quarterNumbers.map(number => ({ number, year: reportingYear }))
  const thisYearsQuarter = [{ number: 1, year: currentYear }]
  let quarters = [...previousYearQuarters, ...thisYearsQuarter]
  return quarters
}

export const monthMap = new Map([
  [1, 'январь'],
  [2, 'февраль'],
  [3, 'март'],
  [4, 'апрель'],
  [5, 'май'],
  [6, 'июнь'],
  [7, 'июль'],
  [8, 'август'],
  [9, 'сентябрь'],
  [10, 'октябрь'],
  [11, 'ноябрь'],
  [12, 'декабрь']
])

const setProperties = (quarter) => ({
  ...quarter,
  paymentDate: getPaymentDateByQuarterNumberAndQuarterYear(quarter.number, quarter.year).toFormat('dd.MM.yyyy'),
  submissionDate: getSubmissionDateByQuarterNumberAndQuarterYear(quarter.number, quarter.year).toFormat('dd.MM.yyyy'),
  unaccountedRevenue: '0,00',
  unaccountedExpenses: '0,00',
  socialDeduction: '0,00',
  propertyDeduction: '0,00',
  children: [0, 0, 0],
  dependents: [0, 0, 0],
  isEmployed: [false, false, false],
  isSpecialParent: [false, false, false],
  isSpecialStatus: [false, false, false],
  incomeTax: ['0,00', '0,00', '0,00'],
  wageFund: ['0,00', '0,00', '0,00'],
  wageRate: ['0,00', '0,00', '0,00']
})

export const getStartOfQuarter = ({ number, year }) => DateTime.fromObject({ month: (number * 3) - 2, year: year })
export const getEndOfQuarter = ({ number, year }) => getStartOfQuarter({ number, year }).endOf('quarter')

const generateInterval = ({ number, year }) => ([getStartOfQuarter({ number, year }), getEndOfQuarter({ number, year })])

export const generatePeriodIntervals = () => generateQuarters().map(generateInterval)