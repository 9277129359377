<script>
  import { onMount } from 'svelte'
  import { parse } from 'cookie'
  import BitTaxLogo from './logos/bittax.logo.svelte'
  import AlfaLogo from './logos/alfa.logo.svelte'
  import BSBLogo from './logos/bsb.logo.svelte'
  import PriorLogo from './logos/prior.logo.svelte'
  import DabrabytLogo from './logos/dabrabyt.logo.svelte'
  import Button from './controls/button.svelte'
  import { bank, tooltip } from '../modules/store.module'

  let userHasAccessToken = false

	onMount(async () => {
    const accessToken = parse(document.cookie).access_token
    const accessTokenExists = Boolean(accessToken)
    if (accessTokenExists) userHasAccessToken = true
	})

  const openTooltip = ({ target }) => {
    const contents = target.querySelector('.header__help-contents').innerHTML
    $tooltip = { heading: 'Помощь в работе с сервисом', contents }
  }
</script>

<header class="header">
  <div class="flex items-center gap-4 sm:gap-8">
    <BitTaxLogo />
    {#if $bank === 'alfa'}
      <AlfaLogo />
    {:else if $bank === 'bsb'}
      <BSBLogo />
    {:else if $bank === 'dabrabyt'}
      <DabrabytLogo />
    {:else if $bank === 'prior'}
      <PriorLogo />
    {/if}
  </div>
  <div class="flex items-center gap-3 sm:gap-6">
    <div class="header__help">
      <button class="header__help-button" on:click={openTooltip}>
        <div class="header__help-contents">
          <a href="/instructions" target="_blank" class="header__link">Инструкция по работе с сервисом</a>
          <p>По вопросам, касающимся сервиса, вы можете обратиться по телефону <a class="header__link" href="tel:+375292519334">+375 (29) 251‑93‑34</a>,<br>а также на email <a class="header__link" href="mailto:support@bittax.by">support@bittax.by</a></p>
          <p>© {new Date().getFullYear()}, ООО «Блэк Рокет»</p>
        </div>
      </button>
    </div>
    {#if userHasAccessToken}
      {#if $bank !== 'bsb'}
        <Button type={'tertiary'} href="/auth/signout">Выход</Button>
      {/if}
    {/if}
  </div>
</header>

<style lang="scss">
  .header {
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
    border-bottom: 1px solid #E7E5E4;
    &__help {
      &-button {
        &:before {
          content: 'Помощь';
          font-size: 14px;
          cursor: pointer;
          transition: text-decoration-color .3s ease-in-out;
          text-decoration: underline;
        }
        &:hover:before {
          text-decoration-color: transparent;
        }
      }
      &-contents {
        display: none;
      }
    }
    &__logos {
      align-items: center;
      display: flex;
      gap: 48px;
      line-height: 0;
    }
    &__buttons {
      align-items: center;
      display: flex;
      gap: 24px;
    }
    &__link {
      white-space: nowrap;
      color: #EF3124;
      transition: text-decoration-color .3s ease-in-out;
      text-decoration: underline;
      &:hover {
        text-decoration-color: transparent;
      }
    }
  }
  .link {
    text-decoration: none;
    color: inherit;
    border-bottom: 1px solid;
    transition: opacity 0.3s ease-in-out;
    &:hover {
      opacity: 0.75;
    }
  }
</style>