<script>
  import BigNumber from '../modules/bignumber.module'
  import { monthMap } from '../modules/time.module'
  import Input from './controls/input.svelte'
  import Navigation from './navigation.svelte'
  import { user } from '../modules/store.module'

  export let transactions
  export let incomeTax
  export let totalIncomeTax
  export let totalWageFund
  export let totalWageRate
  export let wageFund
  export let wageRate
  export let profit
  export let number
  export let customProperties

  const isOfMonthIndex = (monthIndex) => ({ date }) => date.month - ((date.quarter - 1) * 3) - 1 === monthIndex
  const isSocialSecurityPayment = ({ code }) => code === '03511'
  const isIncomeTaxPayment = ({ code }) => code === '00101'
  const sumTransactions = (sum, transaction) => sum.plus(transaction.amount.equivalent)
  const addItemToSum = (sum, item) => sum.plus(BigNumber(item || 0))

  const toggleProperty = (propertyName) => {
    if (!customProperties) customProperties = []
    if (!customProperties.includes(propertyName)) return customProperties = [...customProperties, propertyName]
    customProperties = customProperties.filter(customProperty => customProperty !== propertyName)
  }

  $: totalIncomeTax = incomeTax.reduce(addItemToSum, BigNumber(0)).toFormat(2)
  $: totalWageFund = wageFund.reduce(addItemToSum, BigNumber(0)).toFormat(2)
  $: totalWageRate = wageRate.reduce(addItemToSum, BigNumber(0)).toFormat(2)

  const calculateIncomeTax = (monthIndex) => {
    const outgoingTransactionsInMonth = transactions?.filter(isOfMonthIndex(monthIndex))
    const incomeTaxPayments = outgoingTransactionsInMonth?.filter(isIncomeTaxPayment)
    return incomeTaxPayments?.reduce(sumTransactions, new BigNumber(0)).toFormat(2)
  }

  const calculateWageFund = (monthIndex) => {
    const outgoingTransactionsInMonth = transactions?.filter(isOfMonthIndex(monthIndex))
    const socialSecutiryPayments = outgoingTransactionsInMonth?.filter(isSocialSecurityPayment)
    const socialSecurityPaymentsSum = socialSecutiryPayments?.reduce(sumTransactions, new BigNumber(0)).toFormat(2)
    return BigNumber(socialSecurityPaymentsSum).div(35).times(100).toFormat(2)
  }

  $: if (profit && !customProperties?.includes('incomeTax')) {
    for (const monthIndex of [0,1,2]) incomeTax[monthIndex] = calculateIncomeTax(monthIndex)
  }
  $: if (profit && !customProperties?.includes('wageFund')) {
    for (const monthIndex of [0,1,2]) wageFund[monthIndex] = calculateWageFund(monthIndex)
  }
</script>

<section class="workers">
  {#each [0,1,2] as monthIndex}
    {@const numberOfFirstMonthInQuarter = ((number - 1) * 3) + 1}
    {@const monthNumber = numberOfFirstMonthInQuarter + monthIndex} 
    {@const monthName = monthMap.get(monthNumber)} 
    <div class="workers__item">
      <div class="workers__heading">За {monthName}:</div>
      <div class="workers__inputs">
        <Input bind:value={incomeTax[monthIndex]} {customProperties} {toggleProperty} property={'incomeTax'} postfix={'BYN'} type={'float'} tooltipVisible={monthIndex === 0}>
          <span slot="label">Подоходный налог</span>
          <svelte:fragment slot="tooltip">
            <p>Сумма уплаченного подоходного налога в указанном месяце выбранного квартала.</p>
          </svelte:fragment>
        </Input>
        <Input bind:value={wageFund[monthIndex]} {customProperties} {toggleProperty} property={'wageFund'} postfix={'BYN'} type={'float'} tooltipVisible={monthIndex === 0}>
          <span slot="label">Фонд зарплаты</span>
          <svelte:fragment slot="tooltip">
            <p>Сумма денежных средств, выплаченная работникам, включая ФСЗН и подоходный налог, в указанном месяце выбранного квартала.</p>
          </svelte:fragment>
        </Input>


        {#if $user?.type?.code === 1}
          <Input bind:value={wageRate[monthIndex]} type={'float'} tooltipVisible={monthIndex === 0}>
            <span slot="label">Сумма ставок</span>
            <svelte:fragment slot="tooltip">
              <p>Cумма всех ставок ваших работников по состоянию на конец указанного месяца, с точностью до 2 знаков после запятой. У юрлица всегда будет минимум 1 наемный работник — директор, соответственно поле не может быть пустым.</p>
              <p>Пример 1: у вас в штате только 1 директор, работающий по ставке 0,25 в месяц. В поле указанного месяца вы должны вписать 0,25.</p>
              <p>Пример 2: у вас в штате только 1 директор, работающий по ставке 1,00 в месяц. В В поле указанного месяца вы должны вписать 1,00.</p>
              <p>Пример 3: у вас в штате 2 работника (директор и наемный). Директор работает по ставке 1,00 в месяц. Наемный сотрудник по ставке 0,25 в месяц. В поле указанного месяца вы должны вписать 1,25.</p>
              <p>Пример 4: у вас в штате 2 работника (директор и наемный). Директор работает по ставке 1,00 в месяц весь квартал. Наемный сотрудник в первый месяц квартала работал по ставке 0,25, во второй месяц квартала работал по ставке 0,50, в третий месяц квартала по ставке 0,75. В поле первого месяца квартала вы должны вписать 1,25. В поле второго месяца квартала вы должны вписать 1,50. В поле третьего месяца квартала вы должны вписать 1,75.</p>
            </svelte:fragment>
          </Input>
        {:else if $user?.type?.code === 2 && monthIndex === 2}
          <Input bind:value={wageRate[monthIndex]} type={'integer'} tooltipVisible={true}>
            <span slot="label">Кол-во работников</span>
            <svelte:fragment slot="tooltip">
              <p>Количество уникальных наемных работников в штате, а также физических лиц, работавших у вас по договору подряда, с начала года по конец отчетного квартала.</p>
            </svelte:fragment>
          </Input>
        {/if}
      </div>
    </div>
  {/each}
</section>

<Navigation>
  <Input value={totalIncomeTax} enabled={false} type={'float'}>
    <span slot="label">Итого подоходный налог</span>
  </Input>
  <Input value={totalWageFund} enabled={false} type={'float'}>
    <span slot="label">Итого фонд зарплаты</span>
  </Input>
  <Input value={totalWageRate} enabled={false} type={'float'}>
    <span slot="label">Итого {#if $user?.type?.code === 1}ставок{:else}работников{/if}</span>
  </Input>
</Navigation>

<style lang="scss">
  .workers {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: max-content;
    padding: 16px 0;
    row-gap: 16px;
    @media (max-width: 1023px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
    &__item {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      &:nth-child(1) {
        padding-right: 16px;
        border-right: 1px solid #E7E5E4; 
        @media (max-width: 768px) {
          border-right: none;
          padding-right: 0;
        }
      }
      &:nth-child(2) {
        padding-right: 16px;
        border-right: 1px solid #E7E5E4; 
        padding-left: 16px;
        @media (max-width: 1023px) {
          border-right: none;
          padding-right: 0;
        }
        @media (max-width: 768px) {
          padding-left: 0;
          border-right: none;
          padding-right: 0;
        }
      } 
      &:nth-child(3) {
        padding-left: 16px;
        @media (max-width: 1023px) {
          padding-left: 0;
        }
      }
    }
    &__heading {
      color: #EF3124;
      font-size: 14px;
      line-height: 16px;
    }
    &__inputs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 8px 16px;
      @media (max-width: 459px) {
        grid-template-columns: 1fr;
      }
    }
  }
</style>