<script>
  import { tabs } from '../modules/store.module'
  import Calendar from './calendar.svelte'

  export let quarters
</script>

<div class="tabs">
  <div class="tabs__group">
    {#each $tabs.items as tab, index}
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div class="tabs__tab" data-number={index + 1} class:tabs__tab--active={tab.active} on:click={$tabs.activate(tab.name)}>{tab.name}</div>
    {/each} 
  </div>
  <div class="tabs__prolongation">
    <Calendar bind:quarters/>
  </div>
</div>

<style lang="scss">
  .tabs {
    background-color: white;
    position: sticky;
    top: 0;
    display: flex;
    padding-top: 8px;
    border-bottom: 1px solid #D1D5DB;
    z-index: 1;
    &__group {
      display: flex;
    }
    &__tab {
      user-select: none;
      cursor: pointer;
      color: #A8A29F;
      font-size: 14px;
      line-height: 16px;
      padding: 16px;
      border: 1px solid transparent;
      margin-bottom: -1px;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      font-weight: 500;
      display: flex;
      column-gap: 8px;
      align-items: center;
      &:before {
        content: attr(data-number);
        color: white;
        background-color: #E7E5E4;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 14px;
        height: 14px;
        border-radius: 7px;
        font-size: 11px;
      }
      &--active {
        cursor: default;
        color: black;
        border-color: #D1D5DB;
        border-bottom-color: white;
        &:before {
          background-color: #A8A29F;
        }
      }
      @media (max-width: 768px) {
        &:not(.tabs__tab--active) {
          display: none;
        }
      }
    }
    &__prolongation {
      padding-right: 16px;
      flex-grow: 1;
      align-items: center;
      display: flex;
      justify-content: flex-end;
    }
  }
</style>