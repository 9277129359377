import BigNumber from './bignumber.module'

export const mask = (input, [type, limit = undefined]) => {

  const events = ['keypress', 'paste', 'blur', 'focus']

  const handleEvent = (event) => {
    const eventType = event.type
    const eventTypeIsKeyPress = eventType === 'keypress'
    const eventTypeIsPaste = eventType === 'paste'
    const eventTypeIsBlur = eventType === 'blur'
    const eventTypeIsFocus = eventType === 'focus'
    if (eventTypeIsKeyPress) return handleKeyPressEvent(event)
    if (eventTypeIsBlur) return handleBlurEvent(event)
    if (eventTypeIsPaste) return handlePasteEvent(event)
    if (eventTypeIsFocus) return handleFocusEvent(event)
  }

  const handlePasteEvent = (event) => {
    if (type === 'float' || type === 'number') return handleFloatPasteEvent(event)
  }

  const handleFocusEvent = (event) => {
    if (type === 'float') return handleFloatFocusEvent(event)
    if (type === 'integer') return handleIntegerFocusEvent(event)
  }

  const handleIntegerFocusEvent = (event) => {
    const input = event.target
    if (input.value === '0') input.value = ''
  }

  const handleFloatFocusEvent = (event) => {
    const input = event.target
    if (input.value === '0,00') input.value = ''
  }

  const handleFloatPasteEvent = (event) => event.preventDefault()

  const handleIntegerKeyPressEvent = (event) => {
    if (typedCharacterIsNotADigit(event)) return event.preventDefault()
  }

  const handleFloatKeyPressEvent = (event) => {
    const input = event.target
    if (typedCharacterIsNotAPeriodOrAComma(event) && typedCharacterIsNotADigit(event)) return event.preventDefault()
    if (inputValueContainsAComma(input) && typedCharacterIsAPeriodOrAComma(event)) return event.preventDefault()
    if (inputValueDoesNotContainAComma(input) && inputCaretPositionIsMoreThanTwoCharactersFromEnd(input) && typedCharacterIsAPeriodOrAComma(event)) return event.preventDefault()
    if (inputCaretPositionIndexEqualsTo(input, 1) && typedCharacterIsNotAPeriodOrAComma(event) && firstInputValueCharacterIsZero(input)) removeFirstInputCharacter(input)
    if (inputValueContainsAComma(input) && inputCaretPositionIsAfterComma(input) && inputHasTwoCharactersAfterComma(input) && inputHasNoSelection(input)) return event.preventDefault()
    if (inputCaretPositionIndexEqualsTo(input, 0) && inputValueExists(input) && firstInputCharacterIsNotAComma(input) && typedCharacterIsAZero(event) && inputHasNoSelection(input)) return event.preventDefault()
    if (typedCharacterIsAPeriod(event)) typeACommaInsteadOfAPeriod(event)
  }

  const handleFloatBlurEvent = (event) => {
    const input = event.target
    addFloatPointsToInputValue(input)
  }

  const addFloatPointsToInputValue = (input) => {
    const inputValue = input.value.replace(',', '.') || 0
    input.value = BigNumber(inputValue).toFormat(2)
  }

  const handleBlurEvent = (event) => {
    if (type === 'float') return handleFloatBlurEvent(event)
    if (type === 'integer') return handleCountBlurEvent(event)
  }

  const removeFirstCharacterIfZero = (string) => string.startsWith('0') ? string.substring(1) : string

  const handleCountBlurEvent = (event) => {
    const input = event.target
    const currentValue = input.value
    if (currentValue === '') return input.value = '0'
    if (currentValue.length > 1) input.value = [...currentValue].reduce(removeFirstCharacterIfZero, currentValue)
  }

  const handleKeyPressEvent = (event) => {
    const input = event.target
    if (event.metaKey) return
    if (inputCharacterLimitHasBeenReached(input) && inputHasNoSelection(input)) return event.preventDefault()
    if (type === 'integer' || type === 'number') return handleIntegerKeyPressEvent(event)
    if (type === 'float') return handleFloatKeyPressEvent(event)
  }

  const inputCharacterLimitHasBeenReached = (input) => {
    const inputValue = input.value
    const inputValueLength = inputValue.length
    const inputCharacterLimitHasBeenReached = inputValueLength === limit
    return inputCharacterLimitHasBeenReached
  }

  const inputValueDoesNotContainAComma = (input) => {
    const inputValueDoesNotContainAComma = !inputValueContainsAComma(input)
    return inputValueDoesNotContainAComma
  }

  const inputValueExists = (input) => {
    const inputValue = input.value
    const inputValueExists = Boolean(inputValue)
    return inputValueExists
  }

  const inputValueContainsAComma = (input) => {
    const inputValue = input.value
    const inputValueContainsAComma = inputValue.includes(',')
    return inputValueContainsAComma
  }

  const typedCharacterIsAZero = (event) => {
    const typedCharacter = event.key
    const typedCharacterIsAZero = typedCharacter === '0'
    return typedCharacterIsAZero
  }

  const typedCharacterIsAComma = (event) => {
    const typedCharacter = event.key
    const typedCharacterIsAComma = typedCharacter === ','
    return typedCharacterIsAComma
  }

  const typedCharacterIsAPeriod = (event) => {
    const typedCharacter = event.key
    const typedCharacterIsAPeriod = typedCharacter === '.'
    return typedCharacterIsAPeriod
  }

  const typedCharacterIsAPeriodOrAComma = (event) => {
    const typedCharacterIsAPeriodOrAComma = typedCharacterIsAPeriod(event) || typedCharacterIsAComma(event)
    return typedCharacterIsAPeriodOrAComma
  }

  const typedCharacterIsNotAPeriodOrAComma = (event) => {
    const typedCharacterIsNotAPeriodOrAComma = !typedCharacterIsAPeriodOrAComma(event)
    return typedCharacterIsNotAPeriodOrAComma
  }

  const typedCharacterIsADigit = (event) => {
    const typedCharacter = event.key
    if (typedCharacter === ' ') return false
    const typedCharacterAsANumber = Number(typedCharacter)
    const arrayOfNumbersFromZeroToNine = [...Array(10).keys()]
    const typedCharacterIsADigit = arrayOfNumbersFromZeroToNine.includes(typedCharacterAsANumber)
    return typedCharacterIsADigit
  }

  const typedCharacterIsNotADigit = (event) => {
    const typedCharacterIsNotADigit = !typedCharacterIsADigit(event)
    return typedCharacterIsNotADigit
  }

  const firstInputCharacterIsNotAComma = (input) => {
    const inputValue = input.value
    const firstInputCharacter = inputValue.charAt(0)
    const firstInputCharacterIsNotAComma = firstInputCharacter !== ','
    return firstInputCharacterIsNotAComma
  }

  const inputCaretPositionIsMoreThanTwoCharactersFromEnd = (input) => {
    const inputValue = input.value
    const numberOfCharactersInInput = inputValue.length
    const inputSelectionEndPosition = input.selectionEnd
    const inputCaretPositionIsMoreThanTwoCharactersFromEnd = numberOfCharactersInInput - inputSelectionEndPosition > 2
    return inputCaretPositionIsMoreThanTwoCharactersFromEnd
  }

  const inputCaretPositionIsAfterComma = (input) => {
    const inputValue = input.value
    const inputSelectionStartPosition = input.selectionStart
    const inputCommaPositionIndex = inputValue.indexOf(',')
    const inputCaretPositionIsAfterComma = inputSelectionStartPosition > inputCommaPositionIndex
    return inputCaretPositionIsAfterComma
  }

  const inputCaretPositionIndexEqualsTo = (input, position) => {
    const inputSelectionStartPosition = input.selectionStart
    const inputCaretPositionIndexEqualsTo = inputSelectionStartPosition === position
    return inputCaretPositionIndexEqualsTo
  }

  const firstInputValueCharacterIsZero = (input) => {
    const inputValue = input.value
    const firstInputValueCharacter = inputValue.charAt(0)
    const firstInputValueCharacterIsZero = Number(firstInputValueCharacter) === 0
    return firstInputValueCharacterIsZero
  }

  const removeFirstInputCharacter = (input) => {
    const inputValue = input.value
    input.value = inputValue.slice(1)
    input.setSelectionRange(0, 0)
  }

  const inputHasTwoCharactersAfterComma = (input) => {
    const inputValue = input.value
    if (inputValueDoesNotContainAComma(input)) return false
    const charactersAfterComma = inputValue.split(',')[1]
    const numberOfCharactersAfterComma = charactersAfterComma.length
    const inputHasTwoCharactersAfterComma = numberOfCharactersAfterComma === 2
    return inputHasTwoCharactersAfterComma
  }

  const typeACommaInsteadOfAPeriod = (event) => {
    event.preventDefault()
    const input = event.target
    input.setRangeText(',')
    const inputSelectionStartPosition = input.selectionStart
    const newCaretPosition = inputSelectionStartPosition + 1
    input.setSelectionRange(newCaretPosition, newCaretPosition)
  }

  const inputHasNoSelection = (input) => {
    const inputHasNoSelection = !inputHasSelection(input)
    return inputHasNoSelection
  }

  const inputHasSelection = (input) => {
    const inputSelectionStartPosition = input.selectionStart
    const inputSelectionEndPosition = input.selectionEnd
    const inputHasSelection = inputSelectionEndPosition > inputSelectionStartPosition
    return inputHasSelection
  }

  events.forEach((event) => input.addEventListener(event, handleEvent, true))

  return {
    destroy() {
      events.forEach((event) => input.removeEventListener(event, handleEvent, true))
    }
  }
}