<script>
  export let type = null
  export let disabled = false
  export let href = null
  export let loading = false
</script>

{#if href}
  <a class="button {(type && 'button--type_' + type) || ''} {(loading && 'button--loading') || ''} {$$restProps.class || ''}" class:button--disabled={disabled || loading} on:click {href}>
    {#if loading}
      Загрузка…
    {:else}
      <slot></slot>
    {/if}
  </a>
{:else}
  <button class="button {(type && 'button--type_' + type) || ''} {(loading && 'button--loading') || ''} {$$restProps.class || ''}" class:button--disabled={disabled || loading} on:click>
    {#if loading}
      Загрузка…
    {:else}
      <slot></slot>
    {/if}
  </button>
{/if}

<style lang="scss">
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .button {
    position: relative;
    cursor: pointer;
    user-select: none;
    border-radius: 2px;
    border: 1px solid transparent;
    box-sizing: border-box;
    background-color: #EF3124;
    color: white;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    min-width: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    width: 100%;
    transition: all .3s ease-in-out;
    &:hover {
      background-color: hsl(4, 86%, 49%);
    }
    &--loading {
      pointer-events: none;
      &:after {
        content: '';
        display: block;
        height: 16px;
        width: 16px;
        border: 2px solid #D1D5DB;
        animation: spin 1s linear infinite;
        border-radius: 8px;
        border-top-color: #EF3124;
        position: absolute;
        right: 12px;
        top: 13px;
      }
    }
    &--type {
      &_secondary {
        color: black;
        background-color: white;
        border: 1px solid #E7E5E4;
        font-weight: 400;
        &:hover {
          background-color: hsl(0, 0%, 95%);
        }
      }
      &_tertiary {
        color: black;
        background-color: white;
        border: 1px solid #EF3124;
        font-weight: 400;
        &:hover {
          color: white;
          background-color: #EF3124;
          border-color: #EF3124;
        }
      }
    }
    &--disabled {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.3;
    }
    
  }
</style>