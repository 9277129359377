<script>
  import { activeOnboardingStep } from '../modules/store.module'
  import { rate } from '../modules/store.module'
  import BigNumber from '../modules/bignumber.module'
  import Transactions from './transactions.svelte'
  import Navigation from './navigation.svelte'
  import Input from './controls/input.svelte'
  import Hint from './controls/hint.svelte'

  export let transactions
  export let quarter 

  let selectedExpenses
  $: quarter.expenses = $rate === 'ОСНР' ? BigNumber(selectedExpenses).plus(BigNumber(quarter.unaccountedExpenses || 0)).toFormat(2)
                      : $rate === 'ОСН' ? BigNumber(quarter.revenue).div(100).times(20).toFormat(2)
                                        : '0,00'
</script>

<section class="expenses">
  <Transactions {transactions} bind:selectedTransactionSum={selectedExpenses} />

  <Navigation>
    <Input value={selectedExpenses} enabled={false} postfix={'BYN'}>
      <span slot="label">Выбранный расход</span>
      <svelte:fragment slot="tooltip">
        <p>Сумма отмеченных платежей, относящихся к расходам в выбранном квартале.
        Расходы определяются самостоятельно на основании подтверждающих документов.
        Подробнее: п. 15 ст. 205 НК РБ.</p>
        <p>Расходы могут быть на приобретение товаров для перепродажи, материалов, сырья, инструментов, оплату труда работников и т.д.
        Подробнее: п. 18 – 29 ст. 205 НК РБ.</p>
        <p>Что нельзя отнести к расходам? Подробнее: п. 31 ст. 205 НК РБ.</p>
      </svelte:fragment>
    </Input>
    <Input bind:value={quarter.unaccountedExpenses} type={'float'} postfix={'BYN'}>
      <span slot="label">Неучтенный расход</span>
      <svelte:fragment slot="tooltip">
        <p>Сумма денежных средств, относящихся к расходам, потраченных вами не с расчетного счета в банке или наличными за выбранный квартал.</p>
        <p>Расходы определяются самостоятельно на основании подтверждающих документов. Подробнее: п. 15 ст. 205 НК РБ.</p>
        <p>Расходы могут быть на приобретение товаров для перепродажи, материалов, сырья, инструментов, оплату труда работников и т.д. Подробнее: п. 18 – 29 ст. 205 НК РБ.</p>
        <p>Что нельзя отнести к расходам? Подробнее: п. 31 ст. 205 НК РБ.</p>
      </svelte:fragment>
      <svelte:fragment slot="hint">
        {#if $activeOnboardingStep?.name === 'Неучтенный расход' && $activeOnboardingStep?.quarter.year === quarter.year && $activeOnboardingStep?.quarter.number === quarter.number}
          <Hint shifted={true}>Укажите ваши расходы нарастающим итогом по {quarter.number} квартал включительно. Смотрите вашу декларацию за {quarter.number} квартал, Раздел I, п. 3.4 «расходы в соответствии со статьей 205…»</Hint>
        {/if}
      </svelte:fragment>
    </Input>
    <Input value={quarter.expenses} enabled={false} postfix={'BYN'}>
      <span slot="label">Итого расхода</span>
    </Input>
  </Navigation>
</section>

<style lang="scss">
  .expenses {
    display: grid;
    grid-template-rows: max-content  1fr max-content;
  }
</style>