<script>
  export let quarters
  
  const handlePeriodChange = ({ target: { value: selectedQuarterIndex }}) => quarters = quarters.map((quarter, index) => ({ ...quarter, selected: index == selectedQuarterIndex}))
</script>

<div class="calendar">
  <select class="calendar__field" on:change={handlePeriodChange}>
    {#each quarters as quarter, i}
      <option value="{i}" selected={quarter.selected}>{quarter.number} квартал {quarter.year} г.</option>
    {/each}
  </select>
  </div>

<style lang="scss">
  .calendar {
    &:after {
      content: '';
      display: block;
      height: 16px;
      width: 16px;
      border-radius: 8px;
      background-color: #ef3124;
      position: absolute;
      right: 13px;
      top: 25px;
      opacity: 1;
      transform: scale(1);
      animation: pulse 1s infinite;
      z-index: -1;  
    }
    &__field {
      background-color: transparent;
      appearance: none;
      background-image: url('/images/dropdown.svg');
      background-position: right center;
      background-repeat: no-repeat;
      padding-right: 12px;  
      cursor: pointer;
      outline: none;
      font-size: 14px;
    }
  }
  @keyframes pulse {
    0% {
      transform: scale(0.5);
      opacity: .3;
    }

    70% {
      transform: scale(1.5);
      opacity: 0;
    }

    100% {
      transform: scale(0.5);
      opacity: 0;
    }
  }
</style>