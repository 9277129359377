<div class="prior-logo">
  <svg width="187" height="48" viewBox="0 0 187 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g class="logotype">
      <path d="M46.9808 31.7206H41.765V14.7842H57.2386V31.7206H52.0101V19.2686H46.9808V31.7206Z" fill="#2B2D33"/>
      <path d="M68.4407 27.3448C68.8047 27.1522 69.0928 26.8811 69.304 26.5312C69.5152 26.1816 69.6208 25.7819 69.6208 25.3317C69.6208 24.8822 69.5128 24.4758 69.2973 24.1142C69.082 23.7526 68.7887 23.4712 68.4163 23.2703C68.0436 23.0701 67.6288 22.9695 67.1743 22.9695C66.7265 22.9695 66.3168 23.0717 65.9441 23.2763C65.5717 23.4815 65.2803 23.7629 65.069 24.1202C64.8578 24.4784 64.7522 24.8822 64.7522 25.3317C64.7522 25.7735 64.8596 26.1696 65.0748 26.5195C65.2903 26.8684 65.5844 27.1422 65.9568 27.339C66.3294 27.5359 66.7435 27.6338 67.1988 27.6338C67.6626 27.6338 68.0764 27.5375 68.4407 27.3448ZM71.0486 19.4736C72.092 20.0759 72.9199 20.892 73.5324 21.9201C74.1448 22.9491 74.4516 24.0704 74.4516 25.2836C74.4516 26.5051 74.1448 27.6321 73.5324 28.6645C72.9199 29.6976 72.0936 30.5171 71.0546 31.1237C70.0155 31.7303 68.8832 32.0335 67.6583 32.0335C66.6563 32.0335 65.771 31.8045 65.0006 31.3467V35.9999H60.12V18.8339H63.5601L64.2309 19.8469C65.1332 18.9953 66.2753 18.5696 67.6583 18.5696C68.8758 18.5696 70.0052 18.8703 71.0486 19.4736Z" fill="#2B2D33"/>
      <path d="M89.8882 18.8345V31.7206H85.0449V25.7782L80.8723 31.7206H76.3143V18.8345H81.145V24.0058L84.9705 18.8345H89.8882Z" fill="#2B2D33"/>
      <path d="M100.258 27.369C100.63 27.1678 100.923 26.8908 101.133 26.5375C101.344 26.1836 101.45 25.7855 101.45 25.3437C101.45 24.8935 101.344 24.4898 101.133 24.1324C100.923 23.7749 100.63 23.4954 100.258 23.2942C99.8854 23.0941 99.4713 22.9935 99.0161 22.9935C98.5606 22.9935 98.1468 23.0941 97.7741 23.2942C97.4015 23.4954 97.1101 23.7749 96.8988 24.1324C96.6876 24.4898 96.582 24.8935 96.582 25.3437C96.582 25.7855 96.6876 26.1836 96.8988 26.5375C97.1101 26.8908 97.4015 27.1678 97.7741 27.369C98.1468 27.5699 98.5606 27.6705 99.0161 27.6705C99.4713 27.6705 99.8854 27.5699 100.258 27.369ZM95.303 31.1901C94.2013 30.5876 93.3344 29.7717 92.7011 28.7434C92.0673 27.7143 91.7513 26.589 91.7513 25.3684C91.7513 24.1221 92.0673 22.9808 92.7011 21.9443C93.3344 20.9076 94.2057 20.0865 95.3149 19.4792C96.4249 18.8725 97.6585 18.5692 99.0161 18.5692C100.374 18.5692 101.607 18.8725 102.717 19.4792C103.826 20.0865 104.7 20.9076 105.337 21.9443C105.975 22.9808 106.293 24.1221 106.293 25.3684C106.293 26.5976 105.972 27.7263 105.331 28.7554C104.689 29.7841 103.809 30.5979 102.692 31.1958C101.574 31.795 100.337 32.0939 98.979 32.0939C97.6288 32.0939 96.4039 31.7931 95.303 31.1901Z" fill="#2B2D33"/>
      <path d="M115.968 27.3448C116.332 27.1522 116.62 26.8811 116.831 26.5312C117.043 26.1816 117.148 25.7819 117.148 25.3317C117.148 24.8822 117.04 24.4758 116.825 24.1142C116.609 23.7526 116.316 23.4712 115.943 23.2703C115.571 23.0701 115.156 22.9695 114.702 22.9695C114.254 22.9695 113.844 23.0717 113.471 23.2763C113.099 23.4815 112.807 23.7629 112.596 24.1202C112.385 24.4784 112.279 24.8822 112.279 25.3317C112.279 25.7735 112.387 26.1696 112.602 26.5195C112.818 26.8684 113.112 27.1422 113.484 27.339C113.857 27.5359 114.271 27.6338 114.726 27.6338C115.19 27.6338 115.604 27.5375 115.968 27.3448ZM118.576 19.4736C119.619 20.0759 120.447 20.892 121.06 21.9201C121.672 22.9491 121.979 24.0704 121.979 25.2836C121.979 26.5051 121.672 27.6321 121.06 28.6645C120.447 29.6976 119.621 30.5171 118.582 31.1237C117.543 31.7303 116.411 32.0335 115.186 32.0335C114.184 32.0335 113.297 31.8045 112.528 31.3467V35.9999H107.647V18.8339H111.087L111.758 19.8469C112.661 18.9953 113.803 18.5696 115.186 18.5696C116.402 18.5696 117.533 18.8703 118.576 19.4736Z" fill="#2B2D33"/>
      <path d="M131.988 27.4653C132.369 27.2567 132.665 26.9669 132.876 26.5976C133.088 26.2276 133.193 25.8102 133.193 25.3439C133.193 24.87 133.085 24.4442 132.87 24.0659C132.655 23.6882 132.357 23.3951 131.976 23.1862C131.595 22.9777 131.165 22.8728 130.684 22.8728C130.196 22.8728 129.759 22.9777 129.375 23.1862C128.989 23.3951 128.689 23.6866 128.474 24.0599C128.259 24.4333 128.151 24.8617 128.151 25.3439C128.151 25.8102 128.26 26.2276 128.48 26.5976C128.699 26.9669 129.004 27.2567 129.393 27.4653C129.782 27.6741 130.213 27.7788 130.684 27.7788C131.173 27.7788 131.607 27.6741 131.988 27.4653ZM134.509 19.4792C135.61 20.0857 136.468 20.9079 137.08 21.9443C137.693 22.981 137.999 24.1224 137.999 25.3677C137.999 26.5976 137.691 27.7263 137.074 28.7554C136.457 29.7837 135.59 30.5979 134.472 31.1961C133.354 31.795 132.075 32.0941 130.635 32.0941C129.219 32.0941 127.956 31.7926 126.847 31.1901C125.738 30.5871 124.877 29.7717 124.263 28.7426C123.651 27.7146 123.345 26.5892 123.345 25.3677V25.3559V20.8832C123.345 19.3812 123.589 18.0675 124.077 16.9421C124.566 15.8168 125.274 14.9231 126.201 14.2599C127.128 13.5966 128.21 13.1684 129.485 12.9191C130.877 12.6481 133.542 12.1384 134.274 12L135.466 15.6521C134.597 15.9184 131.455 16.5788 130.672 16.8061C130.009 16.9988 129.308 17.2556 128.915 17.6409C128.521 18.0269 128.254 18.5811 128.114 19.3043C128.842 18.8144 129.723 18.5694 130.759 18.5694C132.158 18.5694 133.409 18.8725 134.509 19.4792Z" fill="#2B2D33"/>
      <path d="M147.586 27.3388C147.959 27.1419 148.253 26.8689 148.468 26.5193C148.683 26.1694 148.791 25.7733 148.791 25.3315C148.791 24.882 148.686 24.4782 148.474 24.12C148.263 23.7626 147.972 23.4812 147.599 23.276C147.227 23.0715 146.817 22.9692 146.369 22.9692C145.914 22.9692 145.5 23.0698 145.127 23.27C144.754 23.4712 144.461 23.7526 144.246 24.114C144.031 24.4756 143.922 24.882 143.922 25.3315C143.922 25.7817 144.028 26.1814 144.239 26.531C144.45 26.8809 144.739 27.152 145.103 27.3448C145.467 27.5373 145.881 27.6345 146.345 27.6345C146.8 27.6345 147.214 27.5356 147.586 27.3388ZM153.423 18.8346V25.9946H153.411V31.72H149.822L149.301 30.7678C148.389 31.6118 147.251 32.0342 145.885 32.0342C144.66 32.0342 143.527 31.7301 142.489 31.1235C141.45 30.5168 140.623 29.6974 140.011 28.6652C139.398 27.6319 139.092 26.5049 139.092 25.2833C139.092 24.0701 139.398 22.9491 140.011 21.9201C140.623 20.8918 141.451 20.0759 142.495 19.4733C143.538 18.8701 144.668 18.5696 145.885 18.5696C147.251 18.5696 148.389 18.991 149.301 19.8349L150.009 18.8346H153.423Z" fill="#2B2D33"/>
      <path d="M160.887 31.7206H156.056V18.8345H160.887V22.8846H164.7V18.8345H169.543V31.7206H164.7V27.1281H160.887V31.7206Z" fill="#2B2D33"/>
      <path d="M177.006 31.7206H172.176V18.8345H177.006V22.9454H177.987L179.9 18.8345H185.526L181.713 24.9223L186.172 31.7206H180.608L177.976 27.3571H177.006V31.7206Z" fill="#2B2D33"/>
    </g>
    <g class="logomark">
      <path d="M36.4895 13.7717H26.1448L14.3129 32.7328H24.6478L36.4895 13.7717Z" fill="#FFE000"/>
      <path d="M22.1766 13.7717H11.8317L0 32.7328H10.3347L22.1766 13.7717Z" fill="#FFE000"/>
    </g>
  </svg>
    
</div>

<style lang="scss">
  .prior-logo {
    display: flex;
  }
  .logotype {
    @media (max-width: 520px) {
      display: none;
    }
  }
</style>