<main>
  <div>
    <p>Уважаемый пользователь,</p>
    <p>К сожалению БСБ Банк прекратил предоставлять сервис BitTax своим клиентам.</p>
    <p>Свои пожелания по возобновлению работы сервиса вы можете оставить по телефону службы поддержки банка <a href="tel:+375293062040">+375 (29) 306-20-40</a> либо в чате <a href="https://www.bsb.by/biznes-klienty" target="_blank">на сайте БСБ Банка</a>.</p>
  </div>
</main>

<style lang="scss">
  main {
    background-color: $light-gray;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    width: 100%;
  }
  div {
    padding: 32px;
    max-width: 600px;
  }
  a {
    color: $red;
    white-space: nowrap;
    text-decoration: underline;
  }
  p {
    margin-bottom: 16px;
  }
</style>