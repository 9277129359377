<script>
  import { onMount } from 'svelte'
  import Header from '../header.svelte'
  import Footer from '../footer.svelte'
  import { bank } from '../../modules/store.module'

  let instructionsContainer
  onMount(async () => {
    const request = await fetch('/api/instructions')
    const instructions = await request.text()
    instructionsContainer.insertAdjacentHTML('beforeend', instructions)
  })

</script>

<div class="container">
  <Header />
  <div class="instructions {$bank}" bind:this={instructionsContainer}>
    <h1>Инструкция</h1>
  </div>
</div>
<Footer />

<style lang="scss">
  .container {
    padding: 0 16px;
    box-sizing: border-box;
    max-width: 1280px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content max-content 1fr;
    row-gap: 32px;
  }
</style>