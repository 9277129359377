<script>
  export let checked
  export let indeterminate = null
  export let onChange = null
</script>

<label class="label">
  <input type="checkbox" class="checkbox" bind:checked {indeterminate} on:change={onChange}>
</label>

<style lang="scss">
  .checkbox {
    appearance: none;
    background-color: transparent;
    position: absolute;
  }
  .label {
    display: inline-flex;
    cursor: pointer;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    border: 1px solid #D1D5DB;
    background-repeat: no-repeat;
    background-position: center;
    &:has(>.checkbox:checked)  {
      background-color: #EF3124;
      border-color: #EF3124;
      background-image: url('/images/tick.svg');
    }
    &:has(>.checkbox:indeterminate) {
      background-color: #D1D5DB;
      border-color: #D1D5DB;
      background-image: url('/images/dash.svg');
    }
  }
</style>