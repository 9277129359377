<script>
  import BigNumber from '../modules/bignumber.module'
  import Checkbox from './controls/checkbox.svelte'
  import Transaction from './transaction.svelte'
  import Toggle from './controls/toggle.svelte'
  import Spinner from './controls/spinner.svelte'

  export let transactions
  export let selectedTransactionSum

  let activeTransactionHash

  const sumTransactions = (sum, transaction) => sum.plus(transaction.amount.equivalent)
  
  const handleCheckboxChange = ({ target: { checked }}) => transactions.forEach(transaction => transaction.setSelection(checked))
  const transactionIsSelected = ({ selected }) => selected
  $: selectedTransactions = transactions?.filter(transactionIsSelected)
  $: allTransactionsAreSelected = transactions?.every(transactionIsSelected)
  $: someTransactionsAreSelected = transactions?.some(transactionIsSelected) && !allTransactionsAreSelected
  $: selectedTransactionSum = selectedTransactions?.reduce(sumTransactions, new BigNumber(0)).toFormat(2)
  let showEquivalent = false
</script>

<div class="header {!transactions && 'header--disabled'}">
  <div class="header__control header__control--type_title">
    <Checkbox checked={allTransactionsAreSelected} indeterminate={someTransactionsAreSelected} onChange={handleCheckboxChange} />
    <span class="header__control-label header__control-label--type_title">Платеж</span>
  </div>
  <div class="header__control header__control--type_currency">
    <Toggle bind:checked={showEquivalent}/>
    <span class="header__control-label header__control-label--type_currency">BYN</span>
  </div>
</div>
<div class="transactions">
  {#if !transactions}
    <div class="notification">
      <Spinner/>
      <span class="notification__text">Загружаем транзакции...</span>
    </div>
  {:else}
    {#if transactions.length}
      {#each transactions as transaction}
        <Transaction bind:activeTransactionHash bind:transaction {showEquivalent}/>
      {/each}
    {:else}
      <div class="notification">
        <span class="notification__text">🤨 В выбранном квартале нет транзакций</span>
      </div>
    {/if}
  {/if}
</div>

<style lang="scss">
  .notification {
    display: flex;
    padding: 16px;
    gap: 8px;
    align-items: center;
  }
  .notification__text {
    font-size: 14px;
  }
  .header {
    background-color: white;
    border-bottom: 1px solid #D1D5DB;
    display: flex;
    justify-content: space-between;
    padding: 16px;
    position: sticky;
    top: 58px;
    &--disabled {
      opacity: 0.5;
      pointer-events: none;
    }
    &__control {
      display: flex;
      align-items: center;
      &--type {
        &_currency {
          gap: 4px;
        }
        &_title {
          gap: 16px;
        }
      }
      &-label {
        &--type {
          &_currency {
            color: #A8A29F;
            font-size: 12px;
          }
          &_title {
            line-height: 16px;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }
  }
  .transactions {
    @media (max-width: 768px) {
      padding-bottom: 24px;
    }
  }
</style>